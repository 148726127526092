import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import React from 'react'
import { useMap, useSetBaseMap } from '../../../providers/map-provider'
import './RBaseMapGroup.module.scss'

const RBaseMapGroup: React.FC = () => {
  const { map } = useMap()
  const setMapBase = useSetBaseMap()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMapBase((event.target as HTMLInputElement).value)
  }

  return (
    <FormControl component="fieldset">
      <RadioGroup
        aria-label="base-map"
        name="base-map"
        value={map.baseMapLayer}
        onChange={handleChange}
      >
        <FormControlLabel
          value="osm"
          control={<Radio />}
          label="OSM (OpenStreetMap)"
        />
        <FormControlLabel
          value="satellite"
          control={<Radio />}
          label="Satélite (Esri World Imagery) "
        />
        {/* <FormControlLabel
          value="topographic"
          control={<Radio />}
          label="Topográfico (Esri World Topo Map)"
        /> */}
        <FormControlLabel
          value="streets"
          control={<Radio />}
          label="Ruas (Esri World Street Map)"
        />
        <FormControlLabel
          value="terrain"
          control={<Radio />}
          label="Terreno (OpenTopoMap)"
        />
      </RadioGroup>
    </FormControl>
  )
}

export default RBaseMapGroup
