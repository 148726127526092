import MenuIcon from '@mui/icons-material/Menu'
import { IconButton, Toolbar, styled } from '@mui/material'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'

import { UserAppbar } from '@components/index'
import useUI from 'src/hooks/useUI'
import styles from './Header.module.scss'

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const drawerWidth = 240
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  background: 'white',
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

export type HeaderProps = {
  open: boolean
  openMenu: () => void
}

export function Header(props: HeaderProps) {
  const { open, openMenu } = props

  //hook para os dados da tela
  const content = useUI()

  return (
    <AppBar
      position="fixed"
      open={open}
      className={styles.header}
      sx={{
        boxShadow: 'none',
        borderBottom: '1px solid #f1f1f1',
        padding: '10px',
      }}
    >
      <Toolbar>
        <IconButton
          aria-label="open drawer"
          onClick={openMenu}
          edge="start"
          sx={{
            mr: 2,
            color: 'white',
            background: content?.pages?.admin?.color_primary,
            ...(open && { display: 'none' }),
          }}
          className={styles.header_btnOpen}
        >
          <MenuIcon />
        </IconButton>
        <img
          src={content?.pages?.admin?.logo}
          alt={content?.pages?.admin?.alt_logo}
        />
        <div className={styles.header_user}>
          <UserAppbar tooltip="você está logado com acesso admin" />
        </div>
      </Toolbar>
    </AppBar>
  )
}
