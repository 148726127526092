import React, { PropsWithChildren } from 'react'
import { ThemeProvider, createTheme } from '@mui/material'
import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton'

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    primary: true
    secondary: true
    white: true
    transparent: true
    green: true
    blue: true
    red: true
    custom: true
  }
}
type ButtonDefaultProps = PropsWithChildren<LoadingButtonProps>

export function ButtonDefault(props: ButtonDefaultProps) {
  const { children, ...rest } = props

  const theme = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            paddingTop: '0.8rem',
            paddingBottom: '0.8rem',
            fontSize: '1.25rem',
            color: 'white',
            lineHeight: 1,
            textTransform: 'initial',
            borderRadius: 8,
            fontWeight: 400,
            '.MuiButton-startIcon': {
              marginRight: 20,
              svg: {
                fontSize: '1.5rem',
              },
            },
            '.MuiButton-endIcon': {
              span: {
                color: 'inherit',
              },
            },
            '&.Mui-disabled': {
              color: 'rgba(0, 0, 0, 0.26)',
              boxShadow: 'none',
              backgroundColor: 'rgba(0, 0, 0, 0.12) !important',
            },
          },
        },
        variants: [
          {
            props: { variant: 'white' },
            style: {
              color: 'black',
              backgroundColor: 'white',
              border: '.0625rem solid',
              borderColor: 'white',
              '.MuiButton-startIcon': {
                svg: {
                  color: 'black',
                },
              },
            },
          },
          {
            props: { variant: 'transparent' },
            style: {
              color: 'white',
              backgroundColor: 'transparent',
              border: '.0625rem solid',
              borderColor: 'white',
              '&:hover': {
                backgroundColor: 'transparent',
              },
            },
          },
          {
            props: { variant: 'green' },
            style: {
              color: 'green',
              border: '.0625rem solid',
              borderColor: 'green',
              '.MuiButton-startIcon': {
                svg: {
                  color: 'white',
                },
              },
              '&:hover': {
                backgroundColor: 'green',
                color: 'white',
              },
            },
          },
          {
            props: { variant: 'red' },
            style: {
              color: 'red',
              border: '.0625rem solid',
              borderColor: 'red',
              '.MuiButton-startIcon': {
                svg: {
                  color: 'white',
                },
              },
              '&:hover': {
                backgroundColor: 'red',
                color: 'white',
              },
            },
          },
          {
            props: { variant: 'blue' },
            style: {
              color: 'rgb(29 78 216)',
              border: '.0625rem solid',
              borderColor: 'rgb(29 78 216)',
              '.MuiButton-startIcon': {
                svg: {
                  color: 'white',
                },
              },
              '&:hover': {
                backgroundColor: 'rgb(29 78 216)',
                color: 'white',
              },
            },
          },
        ],
      },
    },
  })

  return (
    <ThemeProvider theme={theme}>
      <LoadingButton type="button" fullWidth {...rest}>
        {children}
      </LoadingButton>
    </ThemeProvider>
  )
}
