import React from 'react'
import { Navigate } from 'react-router-dom'
import { useAuth } from '@lib/contexts/AuthContext'

const withProtectedRoute = <P extends Record<string, unknown>>(
  WrappedComponent: React.ComponentType<P>,
  adminOnly = false
) => {
  const ProtectedRoute: React.FC<P> = (props) => {
    const { userType, getUserTypeFromLocalStorage } = useAuth()

    const currentType = userType || getUserTypeFromLocalStorage()

    if (currentType === 'admin' || (currentType === 'private' && !adminOnly)) {
      return <WrappedComponent {...props} />
    }

    return <Navigate to="/" />
  }

  return ProtectedRoute
}

export default withProtectedRoute
