import { FC } from 'react'
import { RLayerTile, ROSM } from 'rlayers'
import { useMap } from './useMap'

export const useBaseMap = () => {
  const { map } = useMap()

  const BaseMap: FC = () => {
    switch (map.baseMapLayer) {
      case 'osm':
        return <ROSM />
      case 'satellite':
        return (
          <RLayerTile
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
            crossOrigin="anonymous"
            attributions="Tiles © Esri — Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
          />
        )
      case 'topographic':
        return (
          <RLayerTile
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}"
            crossOrigin="anonymous"
            attributions="Tiles © Esri — Esri, DeLorme, FAO, NOAA"
          />
        )
      case 'streets':
        return (
          <RLayerTile
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
            crossOrigin="anonymous"
            attributions="Tiles © Esri — Source: Esri"
          />
        )
      case 'terrain':
        return (
          <RLayerTile
            url="https://{a-c}.tile.opentopomap.org/{z}/{x}/{y}.png"
            crossOrigin="anonymous"
            attributions="Map data © OpenTopoMap contributors"
          />
        )
      default:
        return <ROSM />
    }
  }

  return BaseMap
}
