import { toLonLat } from 'ol/proj'

import { IView } from './interface'
import { useMap } from './useMap'

export const useSetView = () => {
  const { map, setMap } = useMap()

  return (view: IView) => {
    const [lon, lat] = toLonLat(view.center, map.projectionName)

    setMap({
      ...map,
      view: view,
      lon,
      lat,
    })
  }
}
