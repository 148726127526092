import { useContext } from 'react'
import { MapContext } from './map-provider'

export const useMap = () => {
  const context = useContext(MapContext)
  if (!context) {
    throw new Error('useMap deve ser usado dentro de um MapProvider')
  }
  return context
}
