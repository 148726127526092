import { Map } from '@components/index'

export default function Dashboard() {
  return (
    <>
      <Map />
      {/* <Footer /> */}
    </>
  )
}
