import CloseIcon from '@mui/icons-material/Close'
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material'
import React from 'react'
import DynamicTable from './DynamicTable'

import styles from './FeatureInfoModal.module.scss'

const FeatureInfoModal: React.FC<{
  open: boolean
  onClose: () => void
  featureInfo: any
}> = ({ open, onClose, featureInfo }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      className={styles.modal_feature}
    >
      <DialogTitle>
        Informações da Camada
        <IconButton
          aria-label="Fechar"
          onClick={onClose}
          className={styles.modal_feature_close}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {featureInfo && featureInfo.features ? (
          <DynamicTable features={featureInfo.features} />
        ) : (
          'Nenhuma informação disponível.'
        )}
      </DialogContent>
    </Dialog>
  )
}

export default FeatureInfoModal
