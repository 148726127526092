import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  UserUpdateInput,
  useGetRolesQuery,
  useGetUserQuery,
  useUpdateUserMutation,
} from '@graphql/schema'
import {
  ButtonDefault,
  Content,
  Layout,
  SelectInput,
  TextInput,
  TitlePage,
} from '@components/index'
import { FormControlLabel, FormGroup, Switch } from '@mui/material'
import styles from '../../admin.module.scss'

export default function UserUpdate() {
  const navigate = useNavigate()

  const { id: idParam } = useParams<{ id: string | undefined }>()
  const id = idParam ? parseInt(idParam, 10) : undefined

  const { data: dataRoles } = useGetRolesQuery()

  const { data: dataUser, loading: loadingUser } = useGetUserQuery({
    variables: {
      id,
    },
  })

  const [updateUser] = useUpdateUserMutation()

  const [formData, setFormData] = useState<UserUpdateInput>({
    id: id,
    username: '',
    password: '',
    available: false,
    id_role: 1,
  })

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }))
  }

  const roleItems =
    dataRoles?.roles.map((role) => ({
      value: role.id,
      label: role.title,
    })) || []

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      const response = await updateUser({
        variables: {
          data: formData,
        },
      })

      if (response.data) {
        navigate('/admin/users')
      }
    } catch (error) {
      console.error('Ocorreu um erro ao criar o usuário:', error)
    }
  }

  useEffect(() => {
    if (!loadingUser && dataUser?.user) {
      setFormData({
        id: dataUser.user.id,
        username: dataUser.user.username,
        password: '',
        available: dataUser.user.available,
        id_role: dataUser.user.role?.id,
      })
    } else if (!loadingUser && !dataUser?.user) {
      navigate('/admin/users')
    }
  }, [loadingUser, dataUser, navigate])

  return (
    <Layout>
      <Content open={false}>
        <div className="text-center mt-4">
          <TitlePage title="Editar Usuário" />
        </div>
        <div className={styles.form}>
          <form onSubmit={handleSubmit}>
            <div className={styles.form_grup_wrapp}>
              <div className={styles.form_grup}>
                <TextInput
                  type="text"
                  id="username"
                  label="username"
                  name="username"
                  htmlFor="name"
                  value={formData.username}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className={styles.form_grup}>
              <SelectInput
                label="Perfil"
                name="perfil"
                options={roleItems}
                value={formData.id_role || ''}
                onSelectInputChange={() => handleChange}
                required
              />
            </div>
            <div className={styles.form_grup_wrapp}>
              <div className={styles.form_grup}>
                <TextInput
                  type="password"
                  id="password"
                  label="Senha"
                  name="password"
                  htmlFor="password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                />
              </div>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      name="available"
                      checked={!!formData.available}
                      onChange={(e) =>
                        setFormData((prevFormData) => ({
                          ...prevFormData,
                          available: e.target.checked,
                        }))
                      }
                    />
                  }
                  label="Ativo:"
                  labelPlacement="start"
                />
              </FormGroup>
            </div>

            <div className={styles.form_buttons}>
              <ButtonDefault
                variant="green"
                type="submit"
                sx={{ width: '150px' }}
              >
                Salvar
              </ButtonDefault>
              <ButtonDefault
                variant="red"
                type="submit"
                onClick={() => navigate('/admin/users')}
                sx={{ width: '150px' }}
              >
                Cancelar
              </ButtonDefault>
            </div>
          </form>
        </div>
      </Content>
    </Layout>
  )
}
