import { Typography } from '@mui/material'
import { toLonLat } from 'ol/proj'
import React, { useContext, useEffect, useState } from 'react'
import { RContext } from 'rlayers'
import styles from './CoordinateInfo.module.scss'

const CoordinateInfo: React.FC = () => {
  const { map } = useContext(RContext)
  const [coordinates, setCoordinates] = useState<[number, number]>([0, 0])

  useEffect(() => {
    if (!map) return

    const handleMouseMove = (event: MouseEvent) => {
      const projectionName =
        process.env.REACT_APP_MAP_PROJECTION_NAME || 'EPSG:31982'
      const pixel = map.getEventPixel(event)
      const coord = map.getCoordinateFromPixel(pixel)
      const [lon, lat] = toLonLat(coord, projectionName)
      setCoordinates([lon, lat])
    }

    map.getTargetElement().addEventListener('mousemove', handleMouseMove)

    return () =>
      map.getTargetElement().removeEventListener('mousemove', handleMouseMove)
  }, [map])

  return (
    <div className={styles.coordinate}>
      <div className={styles.coordinate_item}>
        <Typography variant="body2" component="p">
          Longitude: {coordinates[0].toFixed(5)}
        </Typography>
      </div>
      <div className={styles.coordinate_item}>
        <Typography variant="body2" component="p">
          Latitude: {coordinates[1].toFixed(5)}
        </Typography>
      </div>
    </div>
  )
}

export default CoordinateInfo
