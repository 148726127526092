import { ActionsTable, TemplateTable } from '@components/admin/templateTable'
import { Content, Filters, Layout, TitlePage } from '@components/index'
import { GetLogsQuery, useGetLogsLazyQuery } from '@graphql/schema'
import { TableCell, TableRow } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'

export default function Logs() {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [filteredResult, setFilteredResults] = useState<GetLogsQuery['logs']>(
    []
  )

  const [fetchData, { loading, error, data }] = useGetLogsLazyQuery({
    fetchPolicy: 'network-only',
  })

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value)
    setPage(1)
  }

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value
    setSearchQuery(query)
    if (query.length >= 1) {
      const filtered =
        data?.logs.filter((log) =>
          log.message.toLowerCase().includes(query.toLowerCase())
        ) || []
      setFilteredResults(filtered)
    } else {
      setFilteredResults(data?.logs || [])
    }
  }

  useEffect(() => {
    if (!loading && data) {
      setFilteredResults(data?.logs)
    } else if (!loading && !data?.logs) {
      fetchData()
    }
  }, [loading, data, fetchData])

  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
        footer: `<p>${error.message}</p>`,
      })
    }
  }, [error])

  return (
    <Layout>
      <Content open={false}>
        <TitlePage title="Logs" />
        <Filters
          id_input="outlined-basic"
          label_input="Digite para filtrar o tipo de perfil..."
          value_input={searchQuery}
          onChange={handleSearchChange}
        />
        <TemplateTable
          table_row={['Id', 'Mensagem', 'Data criação']}
          loading={loading}
          filtered={filteredResult}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        >
          <>
            {filteredResult?.map((log) => (
              <TableRow hover role="checkbox" tabIndex={-1} key={log.id}>
                <TableCell>{log.id}</TableCell>
                <TableCell>{log.message}</TableCell>
                <TableCell>{log.createdAt}</TableCell>
                <TableCell key={log.id}>
                  <ActionsTable url_view={`/admin/logs/${log.id}`} />
                </TableCell>
              </TableRow>
            ))}
          </>
        </TemplateTable>
      </Content>
    </Layout>
  )
}
