import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import { useGetUserQuery } from '@graphql/schema'
import {
  ButtonDefault,
  Content,
  Layout,
  TextInput,
  TitlePage,
} from '@components/index'
import { FormControlLabel, FormGroup, Switch } from '@mui/material'
import styles from '../../admin.module.scss'

export default function UsersUpdate() {
  const navigate = useNavigate()

  const { id: idParam } = useParams<{ id: string | undefined }>()
  const id = idParam ? parseInt(idParam, 10) : undefined

  const {
    data: dataUser,
    loading: loadingUser,
    error: errorUser,
  } = useGetUserQuery({
    variables: {
      id,
    },
  })

  useEffect(() => {
    if (!loadingUser && !dataUser?.user) {
      navigate('/admin/users')
    }
  }, [loadingUser, dataUser, navigate])

  useEffect(() => {
    if (errorUser) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
        footer: `<p>${errorUser.message}</p>`,
      })
    }
  }, [errorUser])

  return (
    <Layout>
      <Content open={false}>
        <div className="text-center mt-4">
          <TitlePage title="Visualizar dados  do usuário" />
        </div>
        <div className={styles.form}>
          <form>
            <div className={styles.form_grup}>
              <div className={styles.form_grup}>
                <TextInput
                  type="text"
                  id="username"
                  label="username"
                  name="username"
                  htmlFor="name"
                  value={dataUser?.user.username}
                  disabled
                />
              </div>
            </div>
            <div className={styles.form_grup}>
              <TextInput
                type="text"
                id="perfil"
                label="Perfil"
                name="perfil"
                htmlFor="perfil"
                value={dataUser?.user.role?.title}
                disabled
              />
            </div>
            <div className={styles.form_grup_wrapp}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      name="available"
                      checked={!!dataUser?.user.available}
                      disabled
                    />
                  }
                  label="Ativo:"
                  labelPlacement="start"
                />
              </FormGroup>
            </div>

            <div className={styles.form_buttons}>
              <ButtonDefault
                variant="blue"
                type="button"
                onClick={() => navigate('/admin/users')}
                sx={{ width: '150px' }}
              >
                Voltar
              </ButtonDefault>
            </div>
          </form>
        </div>
      </Content>
    </Layout>
  )
}
