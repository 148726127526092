import { ActionsTable, TemplateTable } from '@components/admin/templateTable'
import { Content, Filters, Layout, TitlePage } from '@components/index'
import {
  GetUsersQuery,
  useDeleteUserMutation,
  useGetUsersLazyQuery,
} from '@graphql/schema'
import { TableCell, TableRow } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'

export default function Users() {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [filteredUsers, setFilteredUsers] = useState<GetUsersQuery['users']>([])

  const [fetchUsers, { loading, error, data }] = useGetUsersLazyQuery({
    fetchPolicy: 'network-only',
  })
  const [deleteUser] = useDeleteUserMutation()

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value)
    setPage(1)
  }

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log('entrou')
    const query = event.target.value
    setSearchQuery(query)
    if (query.length >= 1) {
      const filtered =
        data?.users.filter((user) =>
          user.username.toLowerCase().includes(query.toLowerCase())
        ) || []
      setFilteredUsers(filtered)
    } else {
      setFilteredUsers(data?.users || [])
    }
  }

  const handleDeleteButtonClick = (userId: number) => {
    Swal.fire({
      title: 'Você tem certeza?',
      text: 'Você não poderá reverter isso!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, delete!',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        deleteUser({
          variables: {
            data: {
              id: userId,
            },
          },
        })
          .then(() => {
            Swal.fire('Deletado!', 'O usuário foi deletado.', 'success')
            fetchUsers()
          })
          .catch((error) => {
            Swal.fire('Erro!', 'O usuário não pôde ser deletado.', 'error')
            console.error(error)
          })
      }
    })
  }

  useEffect(() => {
    if (!loading && data) {
      setFilteredUsers(data?.users)
    } else if (!loading && !data?.users) {
      fetchUsers()
    }
  }, [loading, data, fetchUsers])

  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
        footer: `<p>${error.message}</p>`,
      })
    }
  }, [error])

  return (
    <Layout>
      <Content open={false}>
        <TitlePage title="Usuários" />
        <Filters
          url_create="/admin/users/create"
          label_create="+ Criar novo usuário"
          id_input="outlined-basic"
          label_input="Digite o username para filtrar"
          value_input={searchQuery}
          onChange={handleSearchChange}
        />
        <TemplateTable
          table_row={['Id', 'Username', 'Perfil', 'Data criação', 'Ativo']}
          loading={loading}
          filtered={filteredUsers}
          onClickDelete={handleDeleteButtonClick}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        >
          <>
            {filteredUsers?.map((user) => (
              <TableRow hover role="checkbox" tabIndex={-1} key={user.id}>
                <TableCell>{user.id}</TableCell>
                <TableCell>{user.username}</TableCell>
                <TableCell>{user.role?.title}</TableCell>
                <TableCell>{user.createdAt}</TableCell>
                <TableCell>{user.available ? 'Sim' : 'Não'}</TableCell>
                <TableCell key={user.id}>
                  <ActionsTable
                    url_edit={`/admin/users/edit/${user.id}`}
                    url_view={`/admin/users/${user.id}`}
                    onClickDelete={() => handleDeleteButtonClick(user.id)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </>
        </TemplateTable>
      </Content>
    </Layout>
  )
}
