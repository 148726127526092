import SearchIcon from '@mui/icons-material/Search'
import { IconButton, List, ListItem, ListItemText } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import styles from './Search.module.scss'

export interface SearchResult {
  id: string
  label: string
  lat: number
  lon: number
}

interface SearchProps {
  onSearch: (searchTerm: string) => Promise<SearchResult[]>
  onSelect: (result: SearchResult) => void
}

export const Search: React.FC<SearchProps> = ({ onSearch, onSelect }) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [results, setResults] = useState<SearchResult[]>([])
  const [showResults, setShowResults] = useState(false)
  const searchRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        searchRef.current &&
        !searchRef.current.contains(event.target as Node)
      ) {
        setShowResults(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const handleSearch = async () => {
    if (searchTerm.trim() !== '') {
      const searchResults = await onSearch(searchTerm)
      setResults(searchResults.slice(0, 5))
      setShowResults(true)
    }
  }

  const handleSelect = (result: SearchResult) => {
    onSelect(result)
    setShowResults(false)
    setSearchTerm('')
  }

  return (
    <div className={styles.search} ref={searchRef}>
      <div className={styles.search_container}>
        <input
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Buscar endereço"
          className="placeholder-black bg-white border-0 shadow-none outline-none px-4 py-2 h-11 w-full text-black"
        />

        <IconButton
          onClick={handleSearch}
          sx={{
            fontSize: '14px',
            color: 'black',
            display: 'flex',
            alignItems: 'center',
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }}
        >
          <>
            <SearchIcon />
          </>
        </IconButton>
      </div>
      {showResults && (
        <List className={styles.resultsList}>
          {results.map((result) => (
            <ListItem key={result.id} onClick={() => handleSelect(result)}>
              <ListItemText primary={result.label} />
            </ListItem>
          ))}
        </List>
      )}
    </div>
  )
}

export default Search
