import { Content } from '@interfaces/ui/Ui'

//Assets Login
import loginImage from '../assets/login/banner.jpg'
import loginLogo from '../assets/login/logo.png'

//Assets Admin
import adminLogo from '../assets/admin/logo.jpeg'

const mockUI: Content = {
  pages: {
    login: {
      image: loginImage,
      logo: loginLogo,
      alt_logo: 'Prefeitura Vale Real',
      title: 'Seja-bem vindo(a)!',
      description: 'Navegue pelo mapa de nossa cidade',
      link_color: '#3498db',
      background_button: '#1a9956',
      background_button_hover: '#1a9956',
      banner_mask:
        'linear-gradient(0deg, rgba(19,99,57,1) 0%, rgba(37,148,89,1) 35%, rgba(15,138,73,0.3113620448179272) 100%)',
    },
    admin: {
      logo: adminLogo,
      alt_logo: 'Atagon',
      color_primary: '#6e6e6e',
      color_secundary: '#2ecc71',
    },
    public: {
      logo: loginLogo,
      alt_logo: 'Prefeitura Vale Real',
    },
  },
  template: {
    footer: {
      address: '<p>Rua: Rio Branco, nº 659 <br/> CEP: 95778-000</p>',
      contact: '<p>(51) 3637-7050 <br/> administracao@valereal.rs.gov.br </p>',
      service:
        '<p> Atendimento de segunda-feira a quinta-feira, das 07:30 às 11:30 e das 13:00 às 17:30 horas e Sexta-feira das 7:00 às 13:00 horas. </p>',
      title_color: '#1a9956',
      description_color: '#555555',
      background: '#f1f5f9',
    },
    seo: {
      favicon: 'Rua Exemplo, 123',
      title: '(00) 1234-5678',
      description: 'Seg-Sex, 8h-18h',
    },
  },
}

export default mockUI
