import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import {
  useGetPermissionQuery,
  useGetTranslationKeysQuery,
} from '@graphql/schema'
import {
  ButtonDefault,
  Content,
  Layout,
  TextInput,
  TitlePage,
  SelectInput,
} from '@components/index'
import { FormControlLabel, FormGroup, Switch } from '@mui/material'
import styles from '../../admin.module.scss'

export default function PermissionView() {
  const navigate = useNavigate()

  const { data: dataTranslationKeys } = useGetTranslationKeysQuery()

  const { id: idParam } = useParams<{ id: string | undefined }>()
  const id = idParam ? parseInt(idParam, 10) : undefined

  const { data, loading, error } = useGetPermissionQuery({
    variables: {
      id,
    },
  })

  useEffect(() => {
    if (!loading && !data?.permission) {
      navigate('/admin/permissions')
    }
  }, [loading, data, navigate])

  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
        footer: `<p>${error.message}</p>`,
      })
    }
  }, [error])

  const translationKeyItems =
    dataTranslationKeys?.translationKeys.map((role) => ({
      value: role.id,
      label: role.key,
    })) || []

  return (
    <Layout>
      <Content open={false}>
        <div className="text-center mt-4">
          <TitlePage title="Visualizar permissões" />
        </div>
        <div className={styles.form}>
          <form>
            <div className={styles.form_grup}>
              <TextInput
                type="text"
                id="titulo"
                label="Tipo de permissões"
                name="titulo"
                htmlFor="name"
                value={data?.permission.title}
                disabled
              />
            </div>
            <div className={styles.form_grup}>
              <SelectInput
                label="Chave de tradução"
                name="id_translation_key"
                options={translationKeyItems}
                value={data?.permission?.translationKey?.id || ''}
                disabled
              />
            </div>
            <div className={styles.form_grup_wrapp}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      name="available"
                      checked={data?.permission.available}
                      disabled
                    />
                  }
                  label="Ativo:"
                  labelPlacement="start"
                />
              </FormGroup>
            </div>
            <div className={styles.form_buttons}>
              <ButtonDefault
                variant="blue"
                type="button"
                onClick={() => navigate('/admin/permissions')}
                sx={{ width: '150px' }}
              >
                Voltar
              </ButtonDefault>
            </div>
          </form>
        </div>
      </Content>
    </Layout>
  )
}
