import CloseIcon from '@mui/icons-material/Close'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControlLabel,
  IconButton,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'

import AutoFixOff from '@mui/icons-material/AutoFixOff'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import LayersControl from './components/LayersControl'
import PrintButton from './components/Print'
import RBaseMapGroup from './components/RBaseMapGroup'

import { useState } from 'react'
import { useMap } from '../../providers/map-provider'
import styles from './Map.module.scss'

interface MapProps {
  vectorLayerRef: any
  closeDrawer?: () => void
  selectedAction: 'config' | 'dba' | null
}

export const MapConfig = ({
  vectorLayerRef,
  closeDrawer,
  selectedAction,
}: MapProps): JSX.Element => {
  const { map, setMap } = useMap()
  const [expandedBaseMap, setExpandedBaseMap] = useState<boolean>(false)
  const [expandedLayers, setExpandedLayers] = useState<boolean>(false)
  const [expandedOrtoimage, setExpandedOrtoimage] = useState<boolean>(false)

  const clearDrawings = () => {
    const vectorLayer = vectorLayerRef.current
    if (vectorLayer) {
      const source = vectorLayer.source
      if (source !== null) {
        source.clear()
      }
    }
  }

  return (
    <div className={styles.drawerConfigs}>
      <>
        <div className={styles.drawerConfigs_container}>
          <div className={styles.drawerConfigs_container_close}>
            <IconButton
              onClick={closeDrawer}
              sx={{
                fontSize: '1rem',
                color: 'black',
                display: 'flex',
                alignItems: 'center',
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          {selectedAction === 'config' && (
            <div className="mt-5">
              <div className={styles.drawerConfigs_container_actions_title}>
                <p> Ferramentas </p>
                <hr />
              </div>
              <div className="d-block mb-4">
                <div className={styles.drawerConfigs_container_actions}>
                  <PrintButton />
                </div>
              </div>
              <div className="d-block">
                <FormControlLabel
                  control={
                    <Switch
                      checked={map.isMeasuring}
                      onChange={() => {
                        setMap({
                          ...map,
                          enableDrawMode: false,
                          drawMode: null,
                          isMeasuring: !map.isMeasuring,
                          isMeasuringArea: false,
                        })
                      }}
                      name="measureSwitch"
                      color="primary"
                      sx={{ fontSize: '12px' }}
                    />
                  }
                  label="Medir Distância"
                />
              </div>
              <div className="d-block">
                <FormControlLabel
                  control={
                    <Switch
                      checked={map.isMeasuringArea}
                      onChange={() => {
                        setMap({
                          ...map,
                          enableDrawMode: false,
                          drawMode: null,
                          isMeasuring: false,
                          isMeasuringArea: !map.isMeasuringArea,
                        })
                      }}
                      name="measureSwitchArea"
                      color="primary"
                    />
                  }
                  label="Medir Área"
                />
              </div>
              <div className="d-block">
                <FormControlLabel
                  control={
                    <Switch
                      checked={map.showFeatureInfo}
                      onChange={() => {
                        setMap({
                          ...map,
                          showFeatureInfo: !map.showFeatureInfo,
                        })
                      }}
                      name="showFeatureInfo"
                      color="primary"
                    />
                  }
                  label="Exibir Informações da Camada"
                />
              </div>
              <div className="d-block mb-5">
                <FormControlLabel
                  control={
                    <Switch
                      checked={map.enableDrawMode}
                      onChange={() => {
                        setMap({
                          ...map,
                          enableDrawMode: !map.enableDrawMode,
                          drawMode: !map.enableDrawMode ? '' : map.drawMode,
                          cursorStyle: !map.enableDrawMode
                            ? 'default'
                            : 'pointer',
                          isMeasuring: false,
                          isMeasuringArea: false,
                        })
                      }}
                      name="enableDrawMode"
                      color="primary"
                    />
                  }
                  label="Habilitar Modo de Desenho"
                />
              </div>
              {map.enableDrawMode && (
                <div className="d-block mb-5">
                  <Accordion
                    expanded={expandedOrtoimage}
                    onChange={() => setExpandedOrtoimage(!expandedOrtoimage)}
                    sx={{
                      boxShadow: 'none',
                      border: '1px solid #ddd',
                      marginBottom: '10px',
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography>Controles de Formas Geométricas</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="d-block mb-5">
                        <div className={styles.drawerContainer}>
                          <Button
                            fullWidth
                            onClick={clearDrawings}
                            style={{ borderBottom: '1px solid #ddd' }}
                          >
                            <AutoFixOff className="mr-2" /> Limpar desenho
                          </Button>
                          <Button
                            fullWidth
                            onClick={() =>
                              setMap({
                                ...map,
                                drawMode:
                                  map.drawMode === 'circle' ? '' : 'circle',
                                cursorStyle:
                                  map.drawMode === 'circle'
                                    ? 'default'
                                    : 'pointer',
                              })
                            }
                          >
                            {map.drawMode === 'circle' ? (
                              <VisibilityIcon className="mr-2" />
                            ) : (
                              <VisibilityOffIcon className="mr-2" />
                            )}{' '}
                            Desenhar Círculo
                          </Button>
                          <Button
                            fullWidth
                            onClick={() =>
                              setMap({
                                ...map,
                                drawMode:
                                  map.drawMode === 'square' ? '' : 'square',
                                cursorStyle:
                                  map.drawMode === 'square'
                                    ? 'default'
                                    : 'pointer',
                              })
                            }
                          >
                            {map.drawMode === 'square' ? (
                              <VisibilityIcon className="mr-2" />
                            ) : (
                              <VisibilityOffIcon className="mr-2" />
                            )}{' '}
                            Desenhar Quadrado
                          </Button>
                          <Button
                            fullWidth
                            onClick={() =>
                              setMap({
                                ...map,
                                drawMode:
                                  map.drawMode === 'polygon' ? '' : 'polygon',
                                cursorStyle:
                                  map.drawMode === 'polygon'
                                    ? 'default'
                                    : 'pointer',
                              })
                            }
                          >
                            {map.drawMode === 'polygon' ? (
                              <VisibilityIcon className="mr-2" />
                            ) : (
                              <VisibilityOffIcon className="mr-2" />
                            )}{' '}
                            Desenhar Polígono
                          </Button>
                          <Button
                            fullWidth
                            onClick={() =>
                              setMap({
                                ...map,
                                drawMode: map.drawMode === 'free' ? '' : 'free',
                                cursorStyle:
                                  map.drawMode === 'free'
                                    ? 'default'
                                    : 'pointer',
                              })
                            }
                          >
                            {map.drawMode === 'free' ? (
                              <VisibilityIcon className="mr-2" />
                            ) : (
                              <VisibilityOffIcon className="mr-2" />
                            )}{' '}
                            Desenhar Traço Livre
                          </Button>
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              )}

              <div className={styles.shortcutsTable}>
                <Typography variant="h6" gutterBottom component="div">
                  Atalhos
                </Typography>
                <TableContainer component={Paper}>
                  <Table
                    sx={{
                      minWidth: 417,
                      'th, td': {
                        borderRight: '1px solid rgba(224, 224, 224, 1)',
                      },
                      '& th:last-child, & td:last-child': {
                        borderRight: 'none',
                      },
                    }}
                    aria-label="simple table"
                  >
                    <TableHead
                      sx={{
                        backgroundColor: 'rgba(0, 0, 0, 0.05)',
                      }}
                    >
                      <TableRow>
                        <TableCell
                          sx={{
                            fontWeight: 'bold',
                            color: '#333',
                            fontSize: '1rem',
                          }}
                        >
                          Ação
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: 'bold',
                            color: '#333',
                            fontSize: '1rem',
                          }}
                        >
                          Atalho
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Mover o mapa
                        </TableCell>
                        <TableCell>
                          Arrastar com o botão esquerdo do mouse
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Rotacionar o mapa
                        </TableCell>
                        <TableCell>
                          Alt + Shift + Botão esquerdo do mouse
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Janela de Zoom In
                        </TableCell>
                        <TableCell>
                          Shift + Arrastar/Botão direito do mouse
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Importar e Visualizar KML
                        </TableCell>
                        <TableCell>Arrastar e soltar arquivo .KML</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          )}
          {selectedAction === 'dba' && (
            <div className="mt-5">
              <div className={styles.drawerConfigs_container_actions_title}>
                <p> Biblioteca de Dados </p>
                <hr />
              </div>
              <Accordion
                expanded={expandedBaseMap}
                onChange={() => setExpandedBaseMap(!expandedBaseMap)}
                sx={{
                  boxShadow: 'none',
                  border: '1px solid #ddd',
                  marginBottom: '10px',
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>Mapas Base</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="d-block mb-5">
                    <RBaseMapGroup />
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expandedOrtoimage}
                onChange={() => setExpandedOrtoimage(!expandedOrtoimage)}
                sx={{
                  boxShadow: 'none',
                  border: '1px solid #ddd',
                  marginBottom: '10px',
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>Ortoimagem</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="d-block mb-5">
                    <div className="mb-2">
                      <Button
                        variant="outlined"
                        endIcon={
                          map.ortoimageEnabled ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )
                        }
                        onClick={() =>
                          setMap({
                            ...map,
                            ortoimageEnabled: !map.ortoimageEnabled,
                          })
                        }
                        fullWidth
                        style={{
                          justifyContent: 'space-between',
                          zIndex: 1000,
                        }}
                      >
                        <span style={{ textAlign: 'left', flexGrow: 1 }}>
                          Área Urbana 2022
                        </span>
                      </Button>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expandedLayers}
                onChange={() => setExpandedLayers(!expandedLayers)}
                sx={{
                  boxShadow: 'none',
                  border: '1px solid #ddd',
                  marginBottom: '10px',
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>Camadas</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="d-block mb-5">
                    <LayersControl />
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          )}
        </div>
      </>
    </div>
  )
}

export default MapConfig
