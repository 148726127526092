import styles from './Select.module.scss'
import { FormControl, MenuItem, Select, SelectProps } from '@mui/material'

type SelectInputProps = SelectProps & {
  name: string
  label: string
  value: string | number
  options: { value: string | number; label: string }[]
  onSelectInputChange?: (value: string | number) => void
}

export function SelectInput(props: SelectInputProps) {
  const { name, label, options, onSelectInputChange, value, ...rest } = props

  return (
    <div className={styles.textInput}>
      <FormControl fullWidth>
        <label id={name}>{label}</label>
        <Select
          {...rest}
          disabled={rest.disabled}
          labelId={name}
          onChange={(event) => {
            onSelectInputChange &&
              onSelectInputChange(event.target.value as string | number)
          }}
          value={value}
          sx={{ backgroundColor: '#fff', height: '44px', marginTop: '5px' }}
        >
          {options.map((option, i) => {
            return (
              <MenuItem key={i} value={option.value}>
                {option.label}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </div>
  )
}
