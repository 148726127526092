import { useMap } from './useMap'

export const useSetBaseMap = () => {
  const { map, setMap } = useMap()

  return (baseMapLayer: string) => {
    setMap({
      ...map,
      baseMapLayer,
    })
  }
}
