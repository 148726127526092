import useUI from 'src/hooks/useUI'
import styles from './Footer.module.scss'

export type FooterProps = {
  isPageLogin?: boolean
}

export function Footer(props: FooterProps) {
  const { isPageLogin } = props

  //hook para os dados da tela
  const content = useUI()

  return (
    <footer
      className={styles.footer}
      style={{
        backgroundColor: content?.template?.footer?.background,
        display: !isPageLogin ? 'flex' : '',
        justifyContent: !isPageLogin ? 'space-between' : '',
      }}
    >
      <div className={isPageLogin ? styles.footer_item : ''}>
        <div>
          <p style={{ color: content?.template?.footer?.title_color }}>
            <strong>Endereço</strong>
          </p>
          <p
            style={{ color: content?.template?.footer?.description_color }}
            dangerouslySetInnerHTML={{
              __html: content?.template?.footer?.address || '',
            }}
          />
        </div>
      </div>
      <div className={isPageLogin ? styles.footer_item : ''}>
        <p style={{ color: content?.template?.footer?.title_color }}>
          <strong>Contato</strong>
        </p>

        <p
          style={{ color: content?.template?.footer?.description_color }}
          dangerouslySetInnerHTML={{
            __html: content?.template?.footer?.contact || '',
          }}
        />
      </div>
      <div className={isPageLogin ? styles.footer_item : ''}>
        <p style={{ color: content?.template?.footer?.title_color }}>
          <strong>Atendimento</strong>
        </p>
        <p
          style={{ color: content?.template?.footer?.description_color }}
          dangerouslySetInnerHTML={{
            __html: content?.template?.footer?.service || '',
          }}
        />
      </div>
    </footer>
  )
}
