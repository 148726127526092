import React from 'react'
import styles from './TemplateTable.module.scss'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material'

interface TemplateTableProps {
  table_row: string[]
  loading: boolean
  filtered: Array<{ id: number; [key: string]: unknown }>
  onClickDelete?: (userId: number) => void
  page: number
  rowsPerPage: number
  handleChangePage: (event: unknown, newPage: number) => void
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void
  children: React.ReactNode
}

export function TemplateTable(props: TemplateTableProps) {
  const {
    table_row,
    loading,
    filtered,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    children,
  } = props
  return (
    <div className={styles.table}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {table_row.map((column, index) => (
                <TableCell key={index}>
                  <strong>{column}</strong>
                </TableCell>
              ))}
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={table_row.length + 1}>Loading...</TableCell>
              </TableRow>
            ) : (
              <>{children}</>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={filtered?.length || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  )
}
