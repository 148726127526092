import { AccountCircle } from '@mui/icons-material'
import {
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styles from './UserAppbar.module.scss'

export type UserAppbarProps = {
  tooltip?: string
  css_position?: 'relative' | 'absolute'
}
export function UserAppbar(props: UserAppbarProps) {
  const { tooltip, css_position } = props
  const navigate = useNavigate()
  const [menuOpen, setMenuOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setMenuOpen(true)
  }

  const handleMenuClose = () => {
    setMenuOpen(false)
  }

  const handleCloseAndNavigate = () => {
    localStorage.removeItem('jwt')
    navigate('/')
    setMenuOpen(false)
  }

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <div
      className={styles.user_appbar}
      style={{
        top: isMobile ? '0px' : 'auto',
        position: css_position,
      }}
    >
      <Tooltip title={tooltip}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenuOpen}
          color="inherit"
          className="shadow-2xl"
          sx={{
            color: 'black',
            background: '#f7f7f7',
            '&:hover': {
              color: 'black',
              backgroundColor: '#f7f7f7',
            },
          }}
        >
          <AccountCircle />
        </IconButton>
      </Tooltip>

      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleMenuClose}
        sx={{
          '& .MuiPaper-root': {
            boxShadow: '0 25px 50px -12px rgb(0 0 0 / 0.25)',
          },
        }}
        className={styles.user_appbar_dropdown}
      >
        <MenuItem onClick={handleCloseAndNavigate}>Sair</MenuItem>
      </Menu>
    </div>
  )
}
