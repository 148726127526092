import { useGeoServerLayersLazyQuery } from '@graphql/schema'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Typography,
} from '@mui/material'
import { ProgressSpinner } from 'primereact/progressspinner'
import React, { useEffect, useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'
import {
  IGroupedLayers,
  ILayer,
  useLayers,
} from '../../../providers/layer-provider'

enum GroupNames {
  IBGE = 'IBGE',
  PMVR = 'Prefeitura Municipal de Vale Real',
}

type GroupNameKey = keyof typeof GroupNames

const LayersControl: React.FC = () => {
  const { groupLayers, setGroupLayers } = useLayers()

  const [fetchLayers, { loading: loadingLayers, data }] =
    useGeoServerLayersLazyQuery({
      fetchPolicy: 'network-only',
    })

  const tileWMSParams = useMemo(
    () => ({
      SERVICE: 'WMS',
      VERSION: '1.1.0',
      REQUEST: 'GetMap',
      STYLES: '',
      BBOX: '-180,-90,180,90',
      SRS: 'EPSG:4326',
      FORMAT: 'image/png',
    }),
    []
  )

  useEffect(() => {
    fetchLayers()
  }, [fetchLayers])

  useEffect(() => {
    if (data?.geoServerLayers) {
      let layers = new Array<ILayer>()

      const host = process.env.REACT_APP_GEO_SERVER_ADDRESS
      const port = process.env.REACT_APP_GEO_SERVER_PORT

      layers = data.geoServerLayers
        .map((layer, index) => {
          const id = uuidv4()

          const regex = /AW:(\w+)\s*[-_ ]\s*(.+)/
          const matches = layer.name.match(regex)

          const groupSigla = matches ? matches[1] : ''
          const cleanLayerName = matches ? matches[2] : layer.name
          const groupName = getGroupName(groupSigla) || 'Outras'

          const url = `${host}:${port}/geoserver/ows
            ?service=${tileWMSParams.SERVICE}
            &version=${tileWMSParams.VERSION}
            &request=${tileWMSParams.REQUEST}
            &layers=${layer.name}
            &styles=${tileWMSParams.STYLES}
            &bbox=${tileWMSParams.BBOX}
            &srs=${tileWMSParams.SRS}
            &format=${tileWMSParams.FORMAT}
            &TILED=true`

          return {
            id,
            name: cleanLayerName,
            groupName: groupName,
            enabled: false,
            tileWMSOptions: {
              url: url.replace(/\s+/g, ''),
              params: {
                ...tileWMSParams,
                LAYERS: layer.name,
                TILED: true,
              },
            },
            zIndex: index,
          }
        })
        .filter((x) => x.name !== 'otimizada')

      const groupedLayers: IGroupedLayers = layers.reduce<IGroupedLayers>(
        (acc, layer) => {
          const { groupName } = layer
          if (!acc[groupName]) {
            acc[groupName] = []
          }
          acc[groupName].push(layer)
          return acc
        },
        {}
      )

      const updatedGroupLayers = Object.keys(groupedLayers).map((groupName) => {
        return {
          groupName,
          layers: groupedLayers[groupName].map((newLayer) => {
            const existingLayer = groupLayers
              .find((group) => group.groupName === groupName)
              ?.layers.find((layer) => layer.name === newLayer.name)
            return existingLayer
              ? { ...newLayer, enabled: existingLayer.enabled }
              : newLayer
          }),
        }
      })

      setGroupLayers(updatedGroupLayers)
    }
  }, [data, tileWMSParams])

  const handleClearLayers = () => {
    const newGroupLayers = groupLayers.map((group) => ({
      ...group,
      layers: group.layers.map((layer) => ({
        ...layer,
        enabled: false,
      })),
    }))

    setGroupLayers(newGroupLayers)
  }

  const toggleLayerVisibility = (groupIndex: number, layerIndex: number) => {
    const newGroupLayers = [...groupLayers]

    newGroupLayers[groupIndex].layers[layerIndex] = {
      ...newGroupLayers[groupIndex].layers[layerIndex],
      enabled: !newGroupLayers[groupIndex].layers[layerIndex].enabled,
    }

    setGroupLayers(newGroupLayers)
  }

  const getGroupName = (groupSigla: string): string => {
    const key: GroupNameKey = groupSigla as GroupNameKey
    return GroupNames[key] || 'Outras'
  }

  return (
    <>
      {loadingLayers ? (
        <ProgressSpinner />
      ) : (
        <div className="mt-5">
          {groupLayers.map((group, groupIndex) => (
            <Accordion
              key={`${group.groupName}-${groupIndex}`}
              sx={{
                boxShadow: 'none',
                border: '1px solid #ddd',
                marginBottom: '10px',
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>{group.groupName}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="d-block mb-5">
                  {group.layers.map((layer, layerIndex) => (
                    <div key={layer.id} className="mb-2">
                      <Button
                        variant="outlined"
                        endIcon={
                          layer.enabled ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )
                        }
                        onClick={() =>
                          toggleLayerVisibility(groupIndex, layerIndex)
                        }
                        fullWidth
                        style={{
                          justifyContent: 'space-between',
                          zIndex: 1000,
                        }}
                      >
                        <span style={{ textAlign: 'left', flexGrow: 1 }}>
                          {layer.name}
                        </span>
                      </Button>
                    </div>
                  ))}
                </div>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      )}
      <Button fullWidth onClick={handleClearLayers}>
        Limpar Camadas
      </Button>
    </>
  )
}

export default LayersControl
