// src/components/map/RDragAndDrop.tsx

import { Feature } from 'ol'
import { GeoJSON, KML } from 'ol/format'
import { Geometry } from 'ol/geom'
import { DragAndDrop } from 'ol/interaction'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import React, { useContext, useEffect } from 'react'
import { RContext } from 'rlayers'

const RDragAndDrop: React.FC = () => {
  const map = useContext(RContext).map

  useEffect(() => {
    const dragAndDrop = new DragAndDrop({
      formatConstructors: [GeoJSON, KML],
    })

    dragAndDrop.on('addfeatures', (event) => {
      const vectorSource = new VectorSource({
        features: event.features as Feature<Geometry>[],
      })
      map?.addLayer(
        new VectorLayer({
          source: vectorSource,
          zIndex: 9999,
        })
      )
      map?.getView().fit(vectorSource.getExtent())
    })

    map?.addInteraction(dragAndDrop)

    return () => {
      map?.removeInteraction(dragAndDrop)
    }
  }, [map])

  return null
}

export default RDragAndDrop
