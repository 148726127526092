import { initialMapSettings } from './map-provider'
import { useMap } from './useMap'

export const useReset = () => {
  const { map, setMap } = useMap()

  return () => {
    setMap({ ...initialMapSettings, olMap: map.olMap })
  }
}
