import { BrowserRouter, Route, Routes } from 'react-router-dom'

import withProtectedRoute from '@lib/hooks/withProtectedRoute'

import Login from '../pages/login/Login'

import { adminRoutes, privateRoutes, publicRoutes } from './routesConfig'

const Routers = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />

        {/* Admin Routes */}
        {adminRoutes.map((route) => {
          const ProtectedComponent = withProtectedRoute(route.component, true)
          return (
            <Route
              key={route.path}
              path={route.path}
              element={<ProtectedComponent />}
            />
          )
        })}

        {/* Private Routes */}
        {privateRoutes.map((route) => {
          const ProtectedComponent = withProtectedRoute(route.component, false)
          return (
            <Route
              key={route.path}
              path={route.path}
              element={<ProtectedComponent />}
            />
          )
        })}

        {/* Public Routes */}
        {publicRoutes.map((route) => {
          const ProtectedComponent = route.component
          return (
            <Route
              key={route.path}
              path={route.path}
              element={<ProtectedComponent />}
            />
          )
        })}
      </Routes>
    </BrowserRouter>
  )
}

export default Routers
