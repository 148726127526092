import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import {
  ModuleCreateInput,
  useGetTranslationKeysQuery,
  useCreateModuleMutation,
} from '@graphql/schema'
import {
  ButtonDefault,
  Content,
  Layout,
  TextInput,
  TitlePage,
  SelectInput,
} from '@components/index'
import { FormGroup, FormControlLabel, Switch } from '@mui/material'
import styles from '../../admin.module.scss'

export default function ModuleCreate() {
  const navigate = useNavigate()

  const { data: dataTranslationKeys } = useGetTranslationKeysQuery()

  const [createModule, { error }] = useCreateModuleMutation()

  const [formData, setFormData] = useState<ModuleCreateInput>({
    id_translation_key: 0,
    title: '',
    friendlyUrl: '',
    icon: '',
    order: 0,
    superAdmin: false,
    available: false,
  })

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }))
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      const response = await createModule({
        variables: {
          data: formData,
        },
      })

      if (response.data) {
        navigate('/admin/modules')
      }
    } catch (error) {
      console.error('Ocorreu um erro ao criar o módulo:', error)
    }
  }

  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
        footer: `<p>${error.message}</p>`,
      })
    }
  }, [error])

  const translationKeyItems =
    dataTranslationKeys?.translationKeys.map((role) => ({
      value: role.id,
      label: role.key,
    })) || []

  return (
    <Layout>
      <Content open={false}>
        <div className="text-center mt-4">
          <TitlePage title="Criar Novo Módulo" />
        </div>
        <div className={styles.form}>
          <form onSubmit={handleSubmit}>
            <div className={styles.form_grup_wrapp}>
              <div className={styles.form_grup}>
                <TextInput
                  type="text"
                  id="title"
                  label="Nome do módulo"
                  name="title"
                  htmlFor="title"
                  value={formData.title}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className={styles.form_grup_wrapp}>
              <div className={styles.form_grup}>
                <TextInput
                  type="text"
                  id="friendlyUrl"
                  label="Url Amigável"
                  name="friendlyUrl"
                  htmlFor="friendlyUrl"
                  value={formData.friendlyUrl}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className={styles.form_grup}>
              <SelectInput
                label="Chave de tradução"
                name="id_translation_key"
                options={translationKeyItems}
                value={formData.id_translation_key || ''}
                onSelectInputChange={() => handleChange}
                required
              />
            </div>
            <div className={styles.form_grup_wrapp}>
              <div className={styles.form_grup}>
                <TextInput
                  type="text"
                  id="icon"
                  label="Ícone"
                  name="icon"
                  htmlFor="title"
                  value={formData.icon}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className={styles.form_grup_wrapp}>
              <div className={styles.form_grup}>
                <TextInput
                  type="text"
                  id="order"
                  label="Ordem"
                  name="order"
                  htmlFor="title"
                  value={formData.order}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    name="available"
                    checked={!!formData.available}
                    onChange={(e) =>
                      setFormData((prevFormData) => ({
                        ...prevFormData,
                        available: e.target.checked,
                      }))
                    }
                  />
                }
                label="Ativo:"
                labelPlacement="start"
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    name="superAdmin"
                    checked={!!formData.superAdmin}
                    onChange={(e) =>
                      setFormData((prevFormData) => ({
                        ...prevFormData,
                        superAdmin: e.target.checked,
                      }))
                    }
                  />
                }
                label="Super administrador:"
                labelPlacement="start"
              />
            </FormGroup>
            <div className={styles.form_buttons}>
              <ButtonDefault
                variant="green"
                type="submit"
                sx={{ width: '150px' }}
              >
                Salvar
              </ButtonDefault>
              <ButtonDefault
                variant="red"
                type="submit"
                onClick={() => navigate('/admin/modules')}
                sx={{ width: '150px' }}
              >
                Cancelar
              </ButtonDefault>
            </div>
          </form>
        </div>
      </Content>
    </Layout>
  )
}
