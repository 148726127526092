/* eslint-disable @typescript-eslint/no-explicit-any */
import { Drawer, styled, useMediaQuery, useTheme } from '@mui/material'
import { MapBrowserEvent } from 'ol'
import { Draw } from 'ol/interaction'
import { createRegularPolygon } from 'ol/interaction/Draw'
import { fromLonLat, get as getProjection, transform } from 'ol/proj'
import { TileWMS } from 'ol/source'
import { useEffect, useRef, useState } from 'react'
import { RControl, RLayerTileWMS, RLayerVector, RMap, RStyle } from 'rlayers'

import MapActions from './MapActions'
import { MapConfig } from './MapConfig'
import CoordinateInfo from './components/CoordinateInfo'
import FeatureInfoModal from './components/FeatureInfoModal'
import RDragAndDrop from './components/RDragDrop'
// import RLayerTileWMS from './components/RLayerTileWMS'
import RMeasure from './components/RMeasure'
import Search, { SearchResult } from './components/Search'

import useUI from 'src/hooks/useUI'
import { UserAppbar } from '..'
import { useLayers } from '../../providers/layer-provider'
import { useBaseMap, useMap, useSetView } from '../../providers/map-provider'

import 'ol/ol.css'
import styles from './Map.module.scss'

//drawer
const drawerWidth = 480

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean
}>(({ theme, open }) => ({
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: 0,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: `${drawerWidth}px`,
  }),
}))

export const Map = (): JSX.Element => {
  //hooks
  const mapRef = useRef<RMap>(null)
  const vectorLayerRef = useRef<any>()
  const { map, setMap } = useMap()
  const setView = useSetView()
  const BaseMap = useBaseMap()
  const { groupLayers } = useLayers()

  //variables
  const [showFeatureInfoModal, setShowFeatureInfoModal] = useState(false)
  const [featureInfo, setFeatureInfo] = useState<any>(null)

  // const [showDialog, setShowDialog] = useState(false)
  // const [selectedPin, setSelectedPin] = useState<Feature<Geometry> | null>(null)

  //callbacks
  const handleMapClick = (event: MapBrowserEvent<UIEvent>) => {
    if (!map.showFeatureInfo) return

    const olMapInstance = mapRef.current?.ol
    if (!olMapInstance) return

    const dimensions = olMapInstance?.getSize()

    const width = dimensions && dimensions[0] ? dimensions[0] : 0

    const height = dimensions && dimensions[1] ? dimensions[1] : 0

    const view = olMapInstance.getView()

    const viewResolution = view.getResolution()
    if (viewResolution === undefined) return

    const bbox = view.calculateExtent().join(',')

    const projection = view.getProjection()

    const srs = projection.getCode()

    // const coordinate = event.coordinate

    const x = Math.floor(event.pixel[0])

    const y = Math.floor(event.pixel[1])

    const wmsLayer: any = olMapInstance
      .getLayers()
      .getArray()
      .filter((layer) => layer.getVisible())
      .map((layer: any) => {
        if (layer.getSource() instanceof TileWMS) {
          return layer
        }
        return null
      })
      .filter((layer) => layer !== null)

    const lastVisibleLayer =
      Array.isArray(wmsLayer) && wmsLayer.length > 0
        ? wmsLayer[wmsLayer.length - 1]
        : null

    // const lonlat = toLonLat(event.coordinate)

    const info_format = 'application/json'

    if (lastVisibleLayer) {
      const source = lastVisibleLayer.getSource()

      const params = source.getParams()

      const layerNames = params.LAYERS

      const host = process.env.REACT_APP_GEO_SERVER_ADDRESS

      const port = process.env.REACT_APP_GEO_SERVER_PORT

      const url =
        `${host}:${port}/geoserver/wms?` +
        `SERVICE=WMS&VERSION=1.1.1&REQUEST=GetFeatureInfo&` +
        `FORMAT=image/png&TRANSPARENT=true&` +
        `QUERY_LAYERS=${layerNames}&LAYERS=${layerNames}&` +
        `INFO_FORMAT=${info_format}&FEATURE_COUNT=50&` +
        `X=${x}&Y=${y}&SRS=${srs}&WIDTH=${width}&HEIGHT=${height}&BBOX=${bbox}`

      if (url) {
        fetch(url)
          .then((response) => response.json())
          .then((data) => {
            if (layerNames !== 'AW:ortoimagem_vale_real_otimizada') {
              setFeatureInfo(data)
              setShowFeatureInfoModal(true)
            }
          })
          .catch((error) => {
            console.error('Erro ao buscar dados do layer:', error)
          })
      }
    }
  }

  const handleSearch = async (searchTerm: string): Promise<SearchResult[]> => {
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(
          searchTerm
        )}`
      )
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      const data = await response.json()

      return data.map((item: any) => ({
        id: item.place_id,
        label: item.display_name,
        lat: parseFloat(item.lat),
        lon: parseFloat(item.lon),
      }))
    } catch (error) {
      console.error('Error during search: ', error)
      return []
    }
  }

  const handleSelect = (selection: SearchResult) => {
    const mapInstance = map.olMap
    const projectionName =
      process.env.REACT_APP_MAP_PROJECTION_NAME || 'EPSG:31982'

    const coords3857 = fromLonLat([selection.lon, selection.lat])

    const coordsCustomProjection = transform(
      coords3857,
      'EPSG:3857',
      projectionName
    )

    if (mapInstance !== null) {
      mapInstance.getView().setCenter(coordsCustomProjection)
      mapInstance.getView().setZoom(17)
    }
  }

  //drawer
  const [open, setOpen] = useState(false)
  const [selectedAction, setSelectedAction] = useState<'config' | 'dba' | null>(
    null
  )
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const handleDrawerOpen = (action: 'config' | 'dba' | undefined) => {
    setOpen(false)
    setTimeout(() => {
      setOpen(true)
      setSelectedAction(action || null)
    }, 100)
    setSelectedAction(action || null)
  }
  const handleDrawerClose = () => {
    setOpen(false)
  }

  //hook para os dados da tela
  const content = useUI()

  useEffect(() => {
    const updateMapContext = () => {
      if (mapRef.current) {
        const olMapInstance = mapRef.current.ol
        if (olMapInstance) {
          setMap({ ...map, olMap: olMapInstance })
        }
      }
    }

    updateMapContext()
  }, [setMap])

  useEffect(() => {
    if (!mapRef.current) return

    let drawInteraction: Draw | null = null

    switch (map.drawMode) {
      case 'circle':
        drawInteraction = new Draw({
          source: vectorLayerRef.current.source,
          type: 'Circle',
        })
        break
      case 'triangle':
        drawInteraction = new Draw({
          source: vectorLayerRef.current.source,
          type: 'Circle',
          geometryFunction: createRegularPolygon(3),
        })
        break
      case 'square':
        drawInteraction = new Draw({
          source: vectorLayerRef.current.source,
          type: 'Circle',
          geometryFunction: createRegularPolygon(4),
        })
        break
      case 'pentagon':
        drawInteraction = new Draw({
          source: vectorLayerRef.current.source,
          type: 'Circle',
          geometryFunction: createRegularPolygon(5),
        })
        break
      case 'polygon':
        drawInteraction = new Draw({
          source: vectorLayerRef.current.source,
          type: 'Polygon',
        })
        break
      case 'free':
        drawInteraction = new Draw({
          source: vectorLayerRef.current.source,
          type: 'Polygon',
          freehand: true,
        })
        break
      default:
        break
    }

    if (drawInteraction) {
      map.olMap?.addInteraction(drawInteraction)

      // drawInteraction.on('drawend', (e) => {})
    }

    return () => {
      if (drawInteraction) {
        map.olMap?.removeInteraction(drawInteraction)
      }
    }
  }, [map.drawMode])

  return (
    <>
      <Drawer
        sx={{
          width: isMobile ? '100%' : drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: isMobile ? '100%' : drawerWidth,
            boxSizing: 'border-box',
            backgroundColor: '#fff',
            color: 'black',
            border: 0,
            boxShadow: '0 10px 10px rgb(21,21,21, 0.20)',
          },
        }}
        variant={isMobile ? 'temporary' : 'persistent'}
        anchor="left"
        open={open}
      >
        <MapConfig
          vectorLayerRef={vectorLayerRef}
          closeDrawer={handleDrawerClose}
          selectedAction={selectedAction}
        />
      </Drawer>

      <Main open={open}>
        <div style={{ position: 'relative' }}>
          <div className={styles.mapHeader}>
            <div className={styles.mapHeader_logo}>
              <img
                src={content?.pages?.public?.logo}
                alt={content?.pages?.public?.alt_logo}
              />
            </div>
            <MapActions
              vectorLayerRef={vectorLayerRef}
              openDrawer={handleDrawerOpen}
            />
            <div
              style={{
                width: isMobile ? 'auto' : '348px',
                margin: 'auto',
                position: 'relative',
              }}
            >
              <Search onSearch={handleSearch} onSelect={handleSelect} />
            </div>
            <div>
              <UserAppbar
                tooltip="você está logado com acesso público"
                css_position="relative"
              />
            </div>
          </div>
        </div>

        <div style={{ cursor: map.cursorStyle }}>
          <RMap
            ref={mapRef}
            height="100vh"
            initial={map.view}
            view={[map.view, setView]}
            projection={getProjection(map.projectionName) || 'EPSG:31982'}
            enableRotation={true}
            noDefaultControls={true}
            onClick={handleMapClick}
            minZoom={map.minZoom}
            maxZoom={map.maxZoom}
            extent={map.extent}
          >
            <BaseMap />

            {map.ortoimageEnabled && (
              <RLayerTileWMS
                key={`${map.ortoimagemLayer.name.trim()}`}
                url={map.ortoimagemLayer.tileWMSOptions.url}
                params={map.ortoimagemLayer.tileWMSOptions.params}
                zIndex={1}
              />
            )}

            {groupLayers?.map((group) => {
              return group.layers
                .filter((layer) => layer.enabled)
                .map((layer, index) => {
                  const layerIndex = layer.zIndex ? layer.zIndex + 2 : index + 2
                  return (
                    <RLayerTileWMS
                      key={`${layer.name.trim()}-${index}`}
                      url={layer.tileWMSOptions.url}
                      params={{ ...layer.tileWMSOptions.params }}
                      zIndex={layerIndex}
                    />
                  )
                })
            })}

            <RControl.RScaleLine />
            <RControl.RAttribution className={styles.mapAttribution} />
            <RControl.RZoom />
            <RControl.RZoomSlider />

            {map.drawMode && (
              <RLayerVector ref={vectorLayerRef} zIndex={9999}>
                <RStyle.RStyle>
                  <RStyle.RStroke color="#0000ff" width={3} />
                  <RStyle.RFill color="rgba(0, 0, 0, 0)" />
                </RStyle.RStyle>
              </RLayerVector>
            )}

            <RDragAndDrop />

            <RLayerVector zIndex={250}>
              <RStyle.RStyle>
                <RStyle.RStroke
                  color="rgba(0, 0, 0, 0.5)"
                  width={2}
                  lineDash={[10, 10]}
                />
                <RStyle.RFill color="rgba(255, 255, 255, 0.2)" />
              </RStyle.RStyle>
              <RMeasure isMeasuring={map.isMeasuring} line={true} />
            </RLayerVector>

            <RLayerVector zIndex={250}>
              <RStyle.RStyle>
                <RStyle.RStroke
                  color="rgba(0, 0, 0, 0.5)"
                  width={2}
                  lineDash={[10, 10]}
                />
                <RStyle.RFill color="rgba(255, 255, 255, 0.2)" />
              </RStyle.RStyle>
              <RMeasure isMeasuring={map.isMeasuringArea} line={false} />
            </RLayerVector>

            {/* <RLayerVector zIndex={1000}>
            {mockPins.map((pin, index) => (
              <Pin
                key={index}
                projection={map.projectionName}
                coord={[pin.coord[0], pin.coord[1]]}
                info={pin.info}
                setSelectedPin={setSelectedPin}
                setShowDialog={setShowDialog}
              />
            ))}
          </RLayerVector> */}

            <div>
              <CoordinateInfo />
            </div>
          </RMap>
        </div>

        {/* <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
          <DialogTitle>
            {selectedPin ? selectedPin.get('info').description : ''}
          </DialogTitle>
        </Dialog> */}

        <FeatureInfoModal
          open={showFeatureInfoModal}
          featureInfo={featureInfo}
          onClose={() => setShowFeatureInfoModal(false)}
        />
      </Main>
    </>
  )
}

export default Map
