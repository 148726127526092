import { AuthProvider } from '@lib/contexts/AuthContext'
import { PrimeReactProvider } from 'primereact/api'

import LayersProvider from './providers/layer-provider'
import MapProvider from './providers/map-provider'
import Routes from './router/Router'

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <PrimeReactProvider>
          <MapProvider>
            <LayersProvider>
              <Routes />
            </LayersProvider>
          </MapProvider>
        </PrimeReactProvider>
      </AuthProvider>
    </div>
  )
}

export default App
