import jwtDecode from 'jwt-decode'

interface DecodedToken {
  id: number
  id_role: number
  superAdmin: boolean
  username: string
  iat: number
  exp: number
}

export const useUserData = (): DecodedToken | null => {
  const token = localStorage.getItem('jwt')
  if (!token) return null

  const decodedToken: DecodedToken = jwtDecode(token)
  const currentTime = Date.now() / 1000

  if (decodedToken.exp < currentTime) return null

  return decodedToken
}

export default useUserData
