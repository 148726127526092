import React, { ReactNode } from 'react'
import styles from './Content.module.scss'

export type ContentProps = {
  open: boolean
  children: ReactNode
}

export function Content(props: ContentProps) {
  const { children } = props
  return <div className={styles.content}>{children}</div>
}
