//Admin Pages
import AdminDashboard from '../pages/admin/dashboard/Dashboard'

import AdminLogs from '../pages/admin/logs/Logs'
import AdminLogView from '../pages/admin/logs/view/LogView'

import AdminConfigurations from '../pages/admin/configurations/Configurations'
import AdminConfigurationView from '../pages/admin/configurations/view/ConfigurationView'
import AdminConfigurationCreate from '../pages/admin/configurations/create/ConfigurationCreate'
import AdminConfigurationUpdate from '../pages/admin/configurations/update/ConfigurationUpdate'

import AdminUsers from '../pages/admin/users/Users'
import AdminUserView from '../pages/admin/users/view/UserView'
import AdminUserCreate from '../pages/admin/users/create/UserCreate'
import AdminUserUpdate from '../pages/admin/users/update/UserUpdate'

import AdminRoles from '../pages/admin/roles/Roles'
import AdminRoleView from '../pages/admin/roles/view/RoleView'
import AdminRoleCreate from '../pages/admin/roles/create/RoleCreate'
import AdminRoleUpdate from '../pages/admin/roles/update/RoleUpdate'

import AdminModules from '../pages/admin/modules/Modules'
import AdminModuleView from '../pages/admin/modules/view/ModuleView'
import AdminModuleCreate from '../pages/admin/modules/create/ModuleCreate'
import AdminModuleUpdate from '../pages/admin/modules/update/ModuleUpdate'

import AdminPermissions from '../pages/admin/permissions/Permissions'
import AdminPermissionView from '../pages/admin/permissions/view/PermissionView'
import AdminPermissionCreate from '../pages/admin/permissions/create/PermissionCreate'
import AdminPermissionUpdate from '../pages/admin/permissions/update/PermissionUpdate'

//Private Pages
import PrivateDashboard from '../pages/private/dashboard/Dashboard'

//Public Pages
import PublicDashboard from '../pages/public/dashboard/Dashboard'

interface RouteConfig<P = Record<string, unknown>> {
  path: string
  component: React.ComponentType<P>
}

const adminRoutes: RouteConfig[] = [
  { path: '/admin', component: AdminDashboard },
  { path: '/admin/logs', component: AdminLogs },
  { path: '/admin/logs/:id', component: AdminLogView },
  { path: '/admin/configurations/', component: AdminConfigurations },
  { path: '/admin/configurations/:id', component: AdminConfigurationView },
  { path: '/admin/configurations/create', component: AdminConfigurationCreate },
  {
    path: '/admin/configurations/edit/:id',
    component: AdminConfigurationUpdate,
  },
  { path: '/admin/users/', component: AdminUsers },
  { path: '/admin/users/:id', component: AdminUserView },
  { path: '/admin/users/create', component: AdminUserCreate },
  { path: '/admin/users/edit/:id', component: AdminUserUpdate },
  { path: '/admin/roles', component: AdminRoles },
  { path: '/admin/roles/:id', component: AdminRoleView },
  { path: '/admin/roles/create', component: AdminRoleCreate },
  { path: '/admin/roles/edit/:id', component: AdminRoleUpdate },
  { path: '/admin/modules', component: AdminModules },
  { path: '/admin/modules/:id', component: AdminModuleView },
  { path: '/admin/modules/create', component: AdminModuleCreate },
  { path: '/admin/modules/edit/:id', component: AdminModuleUpdate },
  { path: '/admin/permissions', component: AdminPermissions },
  { path: '/admin/permissions/:id', component: AdminPermissionView },
  { path: '/admin/permissions/create', component: AdminPermissionCreate },
  { path: '/admin/permissions/edit/:id', component: AdminPermissionUpdate },
]

const privateRoutes: RouteConfig[] = [
  { path: '/private', component: PrivateDashboard },
]

const publicRoutes: RouteConfig[] = [
  { path: '/public', component: PublicDashboard },
]

export { adminRoutes, privateRoutes, publicRoutes }
