import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  UserCreateInput,
  useGetRolesQuery,
  useCreateUserMutation,
} from '@graphql/schema'
import {
  ButtonDefault,
  Content,
  Layout,
  SelectInput,
  TextInput,
  TitlePage,
} from '@components/index'
import { FormControlLabel, FormGroup, Switch } from '@mui/material'
import styles from '../../admin.module.scss'

export default function UserCreat() {
  const navigate = useNavigate()

  const { data: dataRoles } = useGetRolesQuery()

  const [createUser] = useCreateUserMutation()

  const [formData, setFormData] = useState<UserCreateInput>({
    username: '',
    password: '',
    available: false,
    id_role: 1,
  })

  const handleSelectChange = (name: string, value: string | number) => {
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }))
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }))
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      const response = await createUser({
        variables: {
          data: formData,
        },
      })

      if (response.data) {
        navigate('/admin/users')
      }
    } catch (error) {
      console.error('Ocorreu um erro ao criar o usuário:', error)
    }
  }
  const roleItems =
    dataRoles?.roles.map((role) => ({
      value: role.id,
      label: role.title,
    })) || []

  return (
    <Layout>
      <Content open={false}>
        <div className="text-center mt-4">
          <TitlePage title="Criar Novo Usuário" />
        </div>
        <div className={styles.form}>
          <form onSubmit={handleSubmit}>
            <div className={styles.form_grup_wrapp}>
              <div className={styles.form_grup}>
                <TextInput
                  type="text"
                  id="username"
                  label="username"
                  name="username"
                  htmlFor="name"
                  value={formData.username}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>
            <div className={styles.form_grup}>
              <SelectInput
                label="Perfil"
                name="id_role"
                options={roleItems}
                value={formData.id_role || ''}
                onSelectInputChange={(value) =>
                  handleSelectChange('id_role', value)
                }
                required
              />
            </div>
            <div className={styles.form_grup_wrapp}>
              <div className={styles.form_grup}>
                <TextInput
                  type="password"
                  id="password"
                  label="Senha"
                  name="password"
                  htmlFor="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      name="available"
                      checked={!!formData.available}
                      onChange={(e) =>
                        setFormData((prevFormData) => ({
                          ...prevFormData,
                          available: e.target.checked,
                        }))
                      }
                    />
                  }
                  label="Ativo:"
                  labelPlacement="start"
                />
              </FormGroup>
            </div>

            <div className={styles.form_buttons}>
              <ButtonDefault
                variant="green"
                type="submit"
                sx={{ width: '150px' }}
              >
                Salvar
              </ButtonDefault>
              <ButtonDefault
                variant="red"
                type="submit"
                onClick={() => navigate('/admin/users')}
                sx={{ width: '150px' }}
              >
                Cancelar
              </ButtonDefault>
            </div>
          </form>
        </div>
      </Content>
    </Layout>
  )
}
