import Overlay from 'ol/Overlay'
import { LineString, Polygon } from 'ol/geom'
import { Draw, Modify } from 'ol/interaction'
import { getArea, getLength } from 'ol/sphere'
import React, { useContext, useEffect } from 'react'
import { RContext } from 'rlayers'
import { useMap } from '../../../providers/map-provider'

type RMeasureProps = {
  isMeasuring: boolean
  line: boolean
}

const RMeasure: React.FC<RMeasureProps> = ({ isMeasuring, line }) => {
  const { map } = useMap()
  const vectorsource = useContext(RContext).vectorsource
  const drawType = line ? 'LineString' : 'Polygon'
  useEffect(() => {
    const draw = new Draw({
      source: vectorsource,
      type: drawType,
    })

    const modify = new Modify({ source: vectorsource })

    let measureTooltipElement = document.createElement('div')
    measureTooltipElement.className = 'ol-tooltip ol-tooltip-measure'

    // a new overlay is needed every time a new drawing is completed,
    // since each drawing/measurement will need its own KM,
    const measureTooltip = [
      new Overlay({
        element: measureTooltipElement,
        offset: [0, -15],
        positioning: 'bottom-center',
      }),
    ]

    let sketch

    draw.on('drawstart', (event) => {
      sketch = event.feature
      const geometry = sketch?.getGeometry()
      if (geometry) {
        geometry.on('change', (evt) => {
          const geom = evt.target
          let output
          if (geom instanceof Polygon) {
            output = formatArea(geom)
            measureTooltip[measureTooltip.length - 1].setPosition(
              geom.getInteriorPoint().getCoordinates()
            )
          } else if (geom instanceof LineString) {
            output = formatLength(geom)
            measureTooltip[measureTooltip.length - 1].setPosition(
              geom.getLastCoordinate()
            )
          }
          if (measureTooltipElement && output) {
            measureTooltipElement.innerHTML = output
          }
        })
      }
    })

    draw.on('drawend', () => {
      measureTooltip[measureTooltip.length - 1].setOffset([0, -7])
      measureTooltipElement = document.createElement('div')
      measureTooltipElement.className = 'ol-tooltip ol-tooltip-measure'
      measureTooltip.push(
        new Overlay({
          element: measureTooltipElement,
          offset: [0, -15],
          positioning: 'bottom-center',
        })
      )
      map.olMap?.addOverlay(measureTooltip[measureTooltip.length - 1])
      sketch = null
    })

    if (isMeasuring) {
      map.olMap?.addInteraction(draw)
      map.olMap?.addInteraction(modify)
      map.olMap?.addOverlay(measureTooltip[measureTooltip.length - 1])
    } else {
      map.olMap?.removeInteraction(draw)
      map.olMap?.removeInteraction(modify)
      // Removes all measurement tooltips that were added as
      // overlays
      measureTooltip.forEach((tooltip) => {
        map.olMap?.removeOverlay(tooltip)
      })
      vectorsource?.clear()
    }

    return () => {
      map.olMap?.removeInteraction(draw)
      map.olMap?.removeInteraction(modify)
      // Removes all measurement tooltips that were added as
      // overlays
      measureTooltip.forEach((tooltip) => {
        map.olMap?.removeOverlay(tooltip)
      })
    }
  }, [drawType, isMeasuring, map, vectorsource])

  function formatLength(line: LineString): string {
    const length = getLength(line)
    if (length >= 1000) {
      return (length / 1000).toFixed(2) + ' km'
    } else {
      return length.toFixed(2) + ' m'
    }
  }

  function formatArea(polygon: Polygon): string {
    const area = getArea(polygon)
    if (area >= 10000) {
      return (area / 10000).toFixed(2) + ' ha'
    } else {
      return area.toFixed(2) + ' m²'
    }
  }

  return null
}

export default RMeasure
