import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import { useGetModuleQuery, useGetTranslationKeysQuery } from '@graphql/schema'
import {
  ButtonDefault,
  Content,
  Layout,
  TextInput,
  TitlePage,
  SelectInput,
} from '@components/index'
import { FormGroup, FormControlLabel, Switch } from '@mui/material'
import styles from '../../admin.module.scss'

export default function ModuleView() {
  const navigate = useNavigate()

  const { data: dataTranslationKeys } = useGetTranslationKeysQuery()

  const { id: idParam } = useParams<{ id: string | undefined }>()
  const id = idParam ? parseInt(idParam, 10) : undefined

  const { data, loading, error } = useGetModuleQuery({
    variables: {
      id,
    },
  })

  useEffect(() => {
    if (!loading && !data?.module) {
      navigate('/admin/modules')
    }
  }, [loading, data, navigate])

  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
        footer: `<p>${error.message}</p>`,
      })
    }
  }, [error])

  const translationKeyItems =
    dataTranslationKeys?.translationKeys.map((role) => ({
      value: role.id,
      label: role.key,
    })) || []

  return (
    <Layout>
      <Content open={false}>
        <div className="text-center mt-4">
          <TitlePage title="Visualizar Módulo" />
        </div>
        <div className={styles.form}>
          <form>
            <div className={styles.form_grup}>
              <div className={styles.form_grup}>
                <TextInput
                  type="text"
                  id="title"
                  label="Nome do Módulo"
                  name="title"
                  htmlFor="title"
                  value={data?.module.title}
                  disabled
                />
              </div>
            </div>
            <div className={styles.form_grup}>
              <div className={styles.form_grup}>
                <TextInput
                  type="text"
                  id="friendlyUrl"
                  label="Url Amigável"
                  name="friendlyUrl"
                  htmlFor="friendlyUrl"
                  value={data?.module.friendlyUrl}
                  disabled
                />
              </div>
            </div>
            <div className={styles.form_grup}>
              <SelectInput
                label="Chave de tradução"
                name="id_translation_key"
                options={translationKeyItems}
                value={data?.module?.translationKey?.id || ''}
                disabled
              />
            </div>
            <div className={styles.form_grup_wrapp}>
              <div className={styles.form_grup}>
                <TextInput
                  type="text"
                  id="icon"
                  label="Ícone"
                  name="icon"
                  htmlFor="title"
                  value={data?.module.icon}
                  disabled
                />
              </div>
            </div>
            <div className={styles.form_grup_wrapp}>
              <div className={styles.form_grup}>
                <TextInput
                  type="text"
                  id="order"
                  label="Ordem"
                  name="order"
                  htmlFor="title"
                  value={data?.module.order}
                  disabled
                />
              </div>
            </div>
            <div className={styles.form_grup_wrapp}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      name="available"
                      checked={!!data?.module.available}
                      disabled
                    />
                  }
                  label="Ativo:"
                  labelPlacement="start"
                />
              </FormGroup>
            </div>
            <div className={styles.form_grup_wrapp}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      name="superAdmin"
                      checked={!!data?.module.superAdmin}
                      disabled
                    />
                  }
                  label="Apenas Super Administrador:"
                  labelPlacement="start"
                />
              </FormGroup>
            </div>
            <div className={styles.form_buttons}>
              <ButtonDefault
                variant="blue"
                type="button"
                onClick={() => navigate('/admin/modules')}
                sx={{ width: '150px' }}
              >
                Voltar
              </ButtonDefault>
            </div>
          </form>
        </div>
      </Content>
    </Layout>
  )
}
