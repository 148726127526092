import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
};

export type BaseResponse = {
  __typename?: 'BaseResponse';
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type Configuration = {
  __typename?: 'Configuration';
  available: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userCreated?: Maybe<User>;
  userUpdated?: Maybe<User>;
  value: Scalars['String']['output'];
};

export type ConfigurationCreateInput = {
  available?: InputMaybe<Scalars['Boolean']['input']>;
  description: Scalars['String']['input'];
  title: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type ConfigurationDeleteInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type ConfigurationUpdateInput = {
  available?: InputMaybe<Scalars['Boolean']['input']>;
  description: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  title: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type Layer = {
  __typename?: 'Layer';
  href: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type Log = {
  __typename?: 'Log';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  message: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type LogCreateInput = {
  message: Scalars['String']['input'];
};

export type LogDeleteInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type LogUpdateInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  message: Scalars['String']['input'];
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
  token?: Maybe<Scalars['String']['output']>;
};

export type Module = {
  __typename?: 'Module';
  available: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  friendlyUrl: Scalars['String']['output'];
  icon: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  order: Scalars['Int']['output'];
  superAdmin: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  translationKey: TranslationKey;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userCreated?: Maybe<User>;
  userUpdated?: Maybe<User>;
};

export type ModuleCreateInput = {
  available?: InputMaybe<Scalars['Boolean']['input']>;
  friendlyUrl: Scalars['String']['input'];
  icon: Scalars['String']['input'];
  id_translation_key: Scalars['Int']['input'];
  order: Scalars['Int']['input'];
  superAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  title: Scalars['String']['input'];
};

export type ModuleDeleteInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type ModuleRolePermission = {
  __typename?: 'ModuleRolePermission';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  module: Module;
  permission: Permission;
  role: Role;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userCreated?: Maybe<User>;
  userUpdated?: Maybe<User>;
};

export type ModuleRolePermissionCreateInput = {
  id_module: Scalars['Int']['input'];
  id_permission: Scalars['Int']['input'];
  id_role: Scalars['Int']['input'];
};

export type ModuleRolePermissionDeleteInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type ModuleRolePermissionUpdateInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  id_module: Scalars['Int']['input'];
  id_permission: Scalars['Int']['input'];
  id_role: Scalars['Int']['input'];
};

export type ModuleUpdateInput = {
  available?: InputMaybe<Scalars['Boolean']['input']>;
  friendlyUrl: Scalars['String']['input'];
  icon: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  id_translation_key: Scalars['Int']['input'];
  order: Scalars['Int']['input'];
  superAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  title: Scalars['String']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createConfiguration: Configuration;
  createLog: Log;
  createModule: Module;
  createModuleRolePermission: ModuleRolePermission;
  createPermission: Permission;
  createRole: Role;
  createTranslationKey: TranslationKey;
  createTranslationLanguage: TranslationLanguage;
  createTranslationValue: TranslationValue;
  createUser: User;
  deleteConfiguration: Configuration;
  deleteLog: Log;
  deleteModule: Module;
  deleteModuleRolePermission: ModuleRolePermission;
  deletePermission: Permission;
  deleteRole: Role;
  deleteTranslationKey: TranslationKey;
  deleteTranslationLanguage: TranslationLanguage;
  deleteTranslationValue: TranslationValue;
  deleteUser: User;
  login: LoginResponse;
  updateConfiguration: Configuration;
  updateLog: Log;
  updateModule: Module;
  updateModuleRolePermission: ModuleRolePermission;
  updatePermission: Permission;
  updateRole: Role;
  updateTranslationKey: TranslationKey;
  updateTranslationLanguage: TranslationLanguage;
  updateTranslationValue: TranslationValue;
  updateUser: User;
};


export type MutationCreateConfigurationArgs = {
  data: ConfigurationCreateInput;
};


export type MutationCreateLogArgs = {
  data: LogCreateInput;
};


export type MutationCreateModuleArgs = {
  data: ModuleCreateInput;
};


export type MutationCreateModuleRolePermissionArgs = {
  data: ModuleRolePermissionCreateInput;
};


export type MutationCreatePermissionArgs = {
  data: PermissionCreateInput;
};


export type MutationCreateRoleArgs = {
  data: RoleCreateInput;
};


export type MutationCreateTranslationKeyArgs = {
  data: TranslationKeyCreateInput;
};


export type MutationCreateTranslationLanguageArgs = {
  data: TranslationLanguageCreateInput;
};


export type MutationCreateTranslationValueArgs = {
  data: TranslationValueCreateInput;
};


export type MutationCreateUserArgs = {
  data: UserCreateInput;
};


export type MutationDeleteConfigurationArgs = {
  data: ConfigurationDeleteInput;
};


export type MutationDeleteLogArgs = {
  data: LogDeleteInput;
};


export type MutationDeleteModuleArgs = {
  data: ModuleDeleteInput;
};


export type MutationDeleteModuleRolePermissionArgs = {
  data: ModuleRolePermissionDeleteInput;
};


export type MutationDeletePermissionArgs = {
  data: PermissionDeleteInput;
};


export type MutationDeleteRoleArgs = {
  data: RoleDeleteInput;
};


export type MutationDeleteTranslationKeyArgs = {
  data: TranslationKeyDeleteInput;
};


export type MutationDeleteTranslationLanguageArgs = {
  data: TranslationLanguageDeleteInput;
};


export type MutationDeleteTranslationValueArgs = {
  data: TranslationValueDeleteInput;
};


export type MutationDeleteUserArgs = {
  data: UserDeleteInput;
};


export type MutationLoginArgs = {
  data: UserLoginInput;
};


export type MutationUpdateConfigurationArgs = {
  data: ConfigurationUpdateInput;
};


export type MutationUpdateLogArgs = {
  data: LogUpdateInput;
};


export type MutationUpdateModuleArgs = {
  data: ModuleUpdateInput;
};


export type MutationUpdateModuleRolePermissionArgs = {
  data: ModuleRolePermissionUpdateInput;
};


export type MutationUpdatePermissionArgs = {
  data: PermissionUpdateInput;
};


export type MutationUpdateRoleArgs = {
  data: RoleUpdateInput;
};


export type MutationUpdateTranslationKeyArgs = {
  data: TranslationKeyUpdateInput;
};


export type MutationUpdateTranslationLanguageArgs = {
  data: TranslationLanguageUpdateInput;
};


export type MutationUpdateTranslationValueArgs = {
  data: TranslationValueUpdateInput;
};


export type MutationUpdateUserArgs = {
  data: UserUpdateInput;
};

export type Permission = {
  __typename?: 'Permission';
  available: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  title: Scalars['String']['output'];
  translationKey: TranslationKey;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userCreated?: Maybe<User>;
  userUpdated?: Maybe<User>;
};

export type PermissionCreateInput = {
  available?: InputMaybe<Scalars['Boolean']['input']>;
  id_translation_key: Scalars['Int']['input'];
  title: Scalars['String']['input'];
};

export type PermissionDeleteInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type PermissionUpdateInput = {
  available?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_translation_key: Scalars['Int']['input'];
  title: Scalars['String']['input'];
};

export type Query = {
  __typename?: 'Query';
  configuration: Configuration;
  configurations: Array<Configuration>;
  geoServerLayerUrl: Scalars['String']['output'];
  geoServerLayers: Array<Layer>;
  log: Log;
  logs: Array<Log>;
  module: Module;
  moduleRolePermission: ModuleRolePermission;
  moduleRolePermissions: Array<ModuleRolePermission>;
  modules: Array<Module>;
  permission: Permission;
  permissions: Array<Permission>;
  role: Role;
  roles: Array<Role>;
  translationKey: TranslationKey;
  translationKeys: Array<TranslationKey>;
  translationLanguage: TranslationLanguage;
  translationLanguages: Array<TranslationLanguage>;
  translationValue: TranslationValue;
  translationValues: Array<TranslationValue>;
  user: User;
  users: Array<User>;
};


export type QueryConfigurationArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGeoServerLayerUrlArgs = {
  layerName: Scalars['String']['input'];
};


export type QueryLogArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryModuleArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryModuleRolePermissionArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPermissionArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryRoleArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTranslationKeyArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTranslationLanguageArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTranslationValueArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryUserArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryUsersArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type Role = {
  __typename?: 'Role';
  available: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  title: Scalars['String']['output'];
  translationKey: TranslationKey;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userCreated?: Maybe<User>;
  userUpdated?: Maybe<User>;
};

export type RoleCreateInput = {
  available?: InputMaybe<Scalars['Boolean']['input']>;
  id_translation_key: Scalars['Int']['input'];
  title: Scalars['String']['input'];
};

export type RoleDeleteInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type RoleUpdateInput = {
  available?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_translation_key: Scalars['Int']['input'];
  title: Scalars['String']['input'];
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type TranslationKey = {
  __typename?: 'TranslationKey';
  id: Scalars['Int']['output'];
  key: Scalars['String']['output'];
};

export type TranslationKeyCreateInput = {
  key: Scalars['String']['input'];
};

export type TranslationKeyDeleteInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type TranslationKeyUpdateInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  key: Scalars['String']['input'];
};

export type TranslationLanguage = {
  __typename?: 'TranslationLanguage';
  code: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type TranslationLanguageCreateInput = {
  code: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type TranslationLanguageDeleteInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type TranslationLanguageUpdateInput = {
  code: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
};

export type TranslationValue = {
  __typename?: 'TranslationValue';
  id: Scalars['Int']['output'];
  translationKey: TranslationKey;
  translationLanguage: TranslationLanguage;
  value: Scalars['String']['output'];
};

export type TranslationValueCreateInput = {
  id_translation_Language: Scalars['Int']['input'];
  id_translation_key: Scalars['Int']['input'];
  value: Scalars['String']['input'];
};

export type TranslationValueDeleteInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type TranslationValueUpdateInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  id_translation_Language: Scalars['Int']['input'];
  id_translation_key: Scalars['Int']['input'];
  value: Scalars['String']['input'];
};

export type User = {
  __typename?: 'User';
  available: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  password: Scalars['String']['output'];
  role?: Maybe<Role>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  username: Scalars['String']['output'];
};

export type UserCreateInput = {
  available?: InputMaybe<Scalars['Boolean']['input']>;
  id_role?: InputMaybe<Scalars['Int']['input']>;
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type UserDeleteInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type UserLoginInput = {
  password?: InputMaybe<Scalars['String']['input']>;
  username: Scalars['String']['input'];
};

export type UserUpdateInput = {
  available?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_role?: InputMaybe<Scalars['Int']['input']>;
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type CreateConfigurationMutationVariables = Exact<{
  data: ConfigurationCreateInput;
}>;


export type CreateConfigurationMutation = { __typename?: 'Mutation', createConfiguration: { __typename?: 'Configuration', id: number, title: string, description: string, value: string, available: boolean, createdAt: any, updatedAt?: any | null, userCreated?: { __typename?: 'User', id: number, username: string, role?: { __typename?: 'Role', id: number, title: string } | null } | null, userUpdated?: { __typename?: 'User', id: number, username: string, role?: { __typename?: 'Role', id: number, title: string } | null } | null } };

export type UpdateConfigurationMutationVariables = Exact<{
  data: ConfigurationUpdateInput;
}>;


export type UpdateConfigurationMutation = { __typename?: 'Mutation', updateConfiguration: { __typename?: 'Configuration', id: number, title: string, description: string, value: string, available: boolean, createdAt: any, updatedAt?: any | null, userCreated?: { __typename?: 'User', id: number, username: string, role?: { __typename?: 'Role', id: number, title: string } | null } | null, userUpdated?: { __typename?: 'User', id: number, username: string, role?: { __typename?: 'Role', id: number, title: string } | null } | null } };

export type DeleteConfigurationMutationVariables = Exact<{
  data: ConfigurationDeleteInput;
}>;


export type DeleteConfigurationMutation = { __typename?: 'Mutation', deleteConfiguration: { __typename?: 'Configuration', id: number, title: string, description: string, value: string, available: boolean, createdAt: any, updatedAt?: any | null, userCreated?: { __typename?: 'User', id: number, username: string, role?: { __typename?: 'Role', id: number, title: string } | null } | null, userUpdated?: { __typename?: 'User', id: number, username: string, role?: { __typename?: 'Role', id: number, title: string } | null } | null } };

export type CreateLogMutationVariables = Exact<{
  data: LogCreateInput;
}>;


export type CreateLogMutation = { __typename?: 'Mutation', createLog: { __typename?: 'Log', id: number, message: string } };

export type UpdateLogMutationVariables = Exact<{
  data: LogUpdateInput;
}>;


export type UpdateLogMutation = { __typename?: 'Mutation', updateLog: { __typename?: 'Log', id: number, message: string } };

export type DeleteLogMutationVariables = Exact<{
  data: LogDeleteInput;
}>;


export type DeleteLogMutation = { __typename?: 'Mutation', deleteLog: { __typename?: 'Log', id: number, message: string } };

export type CreateModuleRolePermissionMutationVariables = Exact<{
  data: ModuleRolePermissionCreateInput;
}>;


export type CreateModuleRolePermissionMutation = { __typename?: 'Mutation', createModuleRolePermission: { __typename?: 'ModuleRolePermission', id: number, createdAt: any, updatedAt?: any | null, module: { __typename?: 'Module', title: string }, role: { __typename?: 'Role', title: string }, permission: { __typename?: 'Permission', title: string }, userCreated?: { __typename?: 'User', id: number, username: string, role?: { __typename?: 'Role', id: number, title: string } | null } | null, userUpdated?: { __typename?: 'User', id: number, username: string, role?: { __typename?: 'Role', id: number, title: string } | null } | null } };

export type UpdateModuleRolePermissionMutationVariables = Exact<{
  data: ModuleRolePermissionUpdateInput;
}>;


export type UpdateModuleRolePermissionMutation = { __typename?: 'Mutation', updateModuleRolePermission: { __typename?: 'ModuleRolePermission', id: number, createdAt: any, updatedAt?: any | null, module: { __typename?: 'Module', title: string }, role: { __typename?: 'Role', title: string }, permission: { __typename?: 'Permission', title: string }, userCreated?: { __typename?: 'User', id: number, username: string, role?: { __typename?: 'Role', id: number, title: string } | null } | null, userUpdated?: { __typename?: 'User', id: number, username: string, role?: { __typename?: 'Role', id: number, title: string } | null } | null } };

export type DeleteModuleRolePermissionMutationVariables = Exact<{
  data: ModuleRolePermissionDeleteInput;
}>;


export type DeleteModuleRolePermissionMutation = { __typename?: 'Mutation', deleteModuleRolePermission: { __typename?: 'ModuleRolePermission', id: number, createdAt: any, updatedAt?: any | null, module: { __typename?: 'Module', title: string }, role: { __typename?: 'Role', title: string }, permission: { __typename?: 'Permission', title: string }, userCreated?: { __typename?: 'User', id: number, username: string, role?: { __typename?: 'Role', id: number, title: string } | null } | null, userUpdated?: { __typename?: 'User', id: number, username: string, role?: { __typename?: 'Role', id: number, title: string } | null } | null } };

export type CreateModuleMutationVariables = Exact<{
  data: ModuleCreateInput;
}>;


export type CreateModuleMutation = { __typename?: 'Mutation', createModule: { __typename?: 'Module', id: number, order: number, title: string, friendlyUrl: string, icon: string, superAdmin: boolean, available: boolean, createdAt: any, updatedAt?: any | null, translationKey: { __typename?: 'TranslationKey', key: string }, userCreated?: { __typename?: 'User', id: number, username: string, role?: { __typename?: 'Role', id: number, title: string } | null } | null, userUpdated?: { __typename?: 'User', id: number, username: string, role?: { __typename?: 'Role', id: number, title: string } | null } | null } };

export type UpdateModuleMutationVariables = Exact<{
  data: ModuleUpdateInput;
}>;


export type UpdateModuleMutation = { __typename?: 'Mutation', updateModule: { __typename?: 'Module', id: number, order: number, title: string, friendlyUrl: string, icon: string, superAdmin: boolean, available: boolean, createdAt: any, updatedAt?: any | null, translationKey: { __typename?: 'TranslationKey', key: string }, userCreated?: { __typename?: 'User', id: number, username: string, role?: { __typename?: 'Role', id: number, title: string } | null } | null, userUpdated?: { __typename?: 'User', id: number, username: string, role?: { __typename?: 'Role', id: number, title: string } | null } | null } };

export type DeleteModuleMutationVariables = Exact<{
  data: ModuleDeleteInput;
}>;


export type DeleteModuleMutation = { __typename?: 'Mutation', deleteModule: { __typename?: 'Module', id: number, order: number, title: string, friendlyUrl: string, icon: string, superAdmin: boolean, available: boolean, createdAt: any, updatedAt?: any | null, translationKey: { __typename?: 'TranslationKey', key: string }, userCreated?: { __typename?: 'User', id: number, username: string, role?: { __typename?: 'Role', id: number, title: string } | null } | null, userUpdated?: { __typename?: 'User', id: number, username: string, role?: { __typename?: 'Role', id: number, title: string } | null } | null } };

export type CreatePermissionMutationVariables = Exact<{
  data: PermissionCreateInput;
}>;


export type CreatePermissionMutation = { __typename?: 'Mutation', createPermission: { __typename?: 'Permission', id: number, title: string, available: boolean, createdAt: any, updatedAt?: any | null, translationKey: { __typename?: 'TranslationKey', key: string }, userCreated?: { __typename?: 'User', id: number, username: string, role?: { __typename?: 'Role', id: number, title: string } | null } | null, userUpdated?: { __typename?: 'User', id: number, username: string, role?: { __typename?: 'Role', id: number, title: string } | null } | null } };

export type UpdatePermissionMutationVariables = Exact<{
  data: PermissionUpdateInput;
}>;


export type UpdatePermissionMutation = { __typename?: 'Mutation', updatePermission: { __typename?: 'Permission', id: number, title: string, available: boolean, createdAt: any, updatedAt?: any | null, translationKey: { __typename?: 'TranslationKey', key: string }, userCreated?: { __typename?: 'User', id: number, username: string, role?: { __typename?: 'Role', id: number, title: string } | null } | null, userUpdated?: { __typename?: 'User', id: number, username: string, role?: { __typename?: 'Role', id: number, title: string } | null } | null } };

export type DeletePermissionMutationVariables = Exact<{
  data: PermissionDeleteInput;
}>;


export type DeletePermissionMutation = { __typename?: 'Mutation', deletePermission: { __typename?: 'Permission', id: number, title: string, available: boolean, createdAt: any, updatedAt?: any | null, translationKey: { __typename?: 'TranslationKey', key: string }, userCreated?: { __typename?: 'User', id: number, username: string, role?: { __typename?: 'Role', id: number, title: string } | null } | null, userUpdated?: { __typename?: 'User', id: number, username: string, role?: { __typename?: 'Role', id: number, title: string } | null } | null } };

export type CreateRoleMutationVariables = Exact<{
  data: RoleCreateInput;
}>;


export type CreateRoleMutation = { __typename?: 'Mutation', createRole: { __typename?: 'Role', id: number, title: string, available: boolean, createdAt: any, updatedAt?: any | null, translationKey: { __typename?: 'TranslationKey', key: string }, userCreated?: { __typename?: 'User', id: number, username: string, role?: { __typename?: 'Role', id: number, title: string } | null } | null, userUpdated?: { __typename?: 'User', id: number, username: string, role?: { __typename?: 'Role', id: number, title: string } | null } | null } };

export type UpdateRoleMutationVariables = Exact<{
  data: RoleUpdateInput;
}>;


export type UpdateRoleMutation = { __typename?: 'Mutation', updateRole: { __typename?: 'Role', id: number, title: string, available: boolean, createdAt: any, updatedAt?: any | null, translationKey: { __typename?: 'TranslationKey', key: string }, userCreated?: { __typename?: 'User', id: number, username: string, role?: { __typename?: 'Role', id: number, title: string } | null } | null, userUpdated?: { __typename?: 'User', id: number, username: string, role?: { __typename?: 'Role', id: number, title: string } | null } | null } };

export type DeleteRoleMutationVariables = Exact<{
  data: RoleDeleteInput;
}>;


export type DeleteRoleMutation = { __typename?: 'Mutation', deleteRole: { __typename?: 'Role', id: number, title: string, available: boolean, createdAt: any, updatedAt?: any | null, translationKey: { __typename?: 'TranslationKey', key: string }, userCreated?: { __typename?: 'User', id: number, username: string, role?: { __typename?: 'Role', id: number, title: string } | null } | null, userUpdated?: { __typename?: 'User', id: number, username: string, role?: { __typename?: 'Role', id: number, title: string } | null } | null } };

export type CreateTranslationKeyMutationVariables = Exact<{
  data: TranslationKeyCreateInput;
}>;


export type CreateTranslationKeyMutation = { __typename?: 'Mutation', createTranslationKey: { __typename?: 'TranslationKey', id: number, key: string } };

export type UpdateTranslationKeyMutationVariables = Exact<{
  data: TranslationKeyUpdateInput;
}>;


export type UpdateTranslationKeyMutation = { __typename?: 'Mutation', updateTranslationKey: { __typename?: 'TranslationKey', id: number, key: string } };

export type DeleteTranslationKeyMutationVariables = Exact<{
  data: TranslationKeyDeleteInput;
}>;


export type DeleteTranslationKeyMutation = { __typename?: 'Mutation', deleteTranslationKey: { __typename?: 'TranslationKey', id: number, key: string } };

export type CreateTranslationLanguageMutationVariables = Exact<{
  data: TranslationLanguageCreateInput;
}>;


export type CreateTranslationLanguageMutation = { __typename?: 'Mutation', createTranslationLanguage: { __typename?: 'TranslationLanguage', id: number, code: string, name: string } };

export type UpdateTranslationLanguageMutationVariables = Exact<{
  data: TranslationLanguageUpdateInput;
}>;


export type UpdateTranslationLanguageMutation = { __typename?: 'Mutation', updateTranslationLanguage: { __typename?: 'TranslationLanguage', id: number, code: string, name: string } };

export type DeleteTranslationLanguageMutationVariables = Exact<{
  data: TranslationLanguageDeleteInput;
}>;


export type DeleteTranslationLanguageMutation = { __typename?: 'Mutation', deleteTranslationLanguage: { __typename?: 'TranslationLanguage', id: number, code: string, name: string } };

export type CreateTranslationValueMutationVariables = Exact<{
  data: TranslationValueCreateInput;
}>;


export type CreateTranslationValueMutation = { __typename?: 'Mutation', createTranslationValue: { __typename?: 'TranslationValue', id: number, value: string, translationKey: { __typename?: 'TranslationKey', id: number, key: string }, translationLanguage: { __typename?: 'TranslationLanguage', id: number, code: string, name: string } } };

export type UpdateTranslationValueMutationVariables = Exact<{
  data: TranslationValueUpdateInput;
}>;


export type UpdateTranslationValueMutation = { __typename?: 'Mutation', updateTranslationValue: { __typename?: 'TranslationValue', id: number, value: string, translationKey: { __typename?: 'TranslationKey', id: number, key: string }, translationLanguage: { __typename?: 'TranslationLanguage', id: number, code: string, name: string } } };

export type DeleteTranslationValueMutationVariables = Exact<{
  data: TranslationValueDeleteInput;
}>;


export type DeleteTranslationValueMutation = { __typename?: 'Mutation', deleteTranslationValue: { __typename?: 'TranslationValue', id: number, value: string, translationKey: { __typename?: 'TranslationKey', id: number, key: string }, translationLanguage: { __typename?: 'TranslationLanguage', id: number, code: string, name: string } } };

export type CreateUserMutationVariables = Exact<{
  data: UserCreateInput;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser: { __typename?: 'User', id: number, username: string, password: string, available: boolean, createdAt: any, updatedAt?: any | null, role?: { __typename?: 'Role', id: number, title: string } | null } };

export type UpdateUserMutationVariables = Exact<{
  data: UserUpdateInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'User', id: number, username: string, password: string, available: boolean, createdAt: any, updatedAt?: any | null, role?: { __typename?: 'Role', id: number, title: string } | null } };

export type DeleteUserMutationVariables = Exact<{
  data: UserDeleteInput;
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser: { __typename?: 'User', id: number, username: string, password: string, available: boolean, createdAt: any, updatedAt?: any | null, role?: { __typename?: 'Role', id: number, title: string } | null } };

export type GetConfigurationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetConfigurationsQuery = { __typename?: 'Query', configurations: Array<{ __typename?: 'Configuration', id: number, title: string, description: string, value: string, available: boolean, createdAt: any, updatedAt?: any | null, userCreated?: { __typename?: 'User', id: number, username: string, role?: { __typename?: 'Role', id: number, title: string } | null } | null, userUpdated?: { __typename?: 'User', id: number, username: string, role?: { __typename?: 'Role', id: number, title: string } | null } | null }> };

export type GetConfigurationQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetConfigurationQuery = { __typename?: 'Query', configuration: { __typename?: 'Configuration', id: number, title: string, description: string, value: string, available: boolean, createdAt: any, updatedAt?: any | null, userCreated?: { __typename?: 'User', id: number, username: string, role?: { __typename?: 'Role', id: number, title: string } | null } | null, userUpdated?: { __typename?: 'User', id: number, username: string, role?: { __typename?: 'Role', id: number, title: string } | null } | null } };

export type GeoServerLayerUrlQueryVariables = Exact<{
  layerName: Scalars['String']['input'];
}>;


export type GeoServerLayerUrlQuery = { __typename?: 'Query', geoServerLayerUrl: string };

export type GeoServerLayersQueryVariables = Exact<{ [key: string]: never; }>;


export type GeoServerLayersQuery = { __typename?: 'Query', geoServerLayers: Array<{ __typename?: 'Layer', name: string, href: string }> };

export type GetLogsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLogsQuery = { __typename?: 'Query', logs: Array<{ __typename?: 'Log', id: number, message: string, createdAt: any, updatedAt?: any | null }> };

export type GetLogQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetLogQuery = { __typename?: 'Query', log: { __typename?: 'Log', id: number, message: string, createdAt: any, updatedAt?: any | null } };

export type GetModuleRolePermissionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetModuleRolePermissionsQuery = { __typename?: 'Query', moduleRolePermissions: Array<{ __typename?: 'ModuleRolePermission', id: number, createdAt: any, updatedAt?: any | null, module: { __typename?: 'Module', title: string }, role: { __typename?: 'Role', title: string }, permission: { __typename?: 'Permission', title: string }, userCreated?: { __typename?: 'User', id: number, username: string, role?: { __typename?: 'Role', id: number, title: string } | null } | null, userUpdated?: { __typename?: 'User', id: number, username: string, role?: { __typename?: 'Role', id: number, title: string } | null } | null }> };

export type GetModuleRolePermissionQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetModuleRolePermissionQuery = { __typename?: 'Query', moduleRolePermission: { __typename?: 'ModuleRolePermission', id: number, createdAt: any, updatedAt?: any | null, module: { __typename?: 'Module', title: string }, role: { __typename?: 'Role', title: string }, permission: { __typename?: 'Permission', title: string }, userCreated?: { __typename?: 'User', id: number, username: string, role?: { __typename?: 'Role', id: number, title: string } | null } | null, userUpdated?: { __typename?: 'User', id: number, username: string, role?: { __typename?: 'Role', id: number, title: string } | null } | null } };

export type GetModulesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetModulesQuery = { __typename?: 'Query', modules: Array<{ __typename?: 'Module', id: number, order: number, title: string, friendlyUrl: string, icon: string, superAdmin: boolean, available: boolean, createdAt: any, updatedAt?: any | null, translationKey: { __typename?: 'TranslationKey', id: number, key: string }, userCreated?: { __typename?: 'User', id: number, username: string, role?: { __typename?: 'Role', id: number, title: string } | null } | null, userUpdated?: { __typename?: 'User', id: number, username: string, role?: { __typename?: 'Role', id: number, title: string } | null } | null }> };

export type GetModuleQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetModuleQuery = { __typename?: 'Query', module: { __typename?: 'Module', id: number, order: number, title: string, friendlyUrl: string, icon: string, superAdmin: boolean, available: boolean, createdAt: any, updatedAt?: any | null, translationKey: { __typename?: 'TranslationKey', id: number, key: string }, userCreated?: { __typename?: 'User', id: number, username: string, role?: { __typename?: 'Role', id: number, title: string } | null } | null, userUpdated?: { __typename?: 'User', id: number, username: string, role?: { __typename?: 'Role', id: number, title: string } | null } | null } };

export type GetPermissionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPermissionsQuery = { __typename?: 'Query', permissions: Array<{ __typename?: 'Permission', id: number, title: string, available: boolean, createdAt: any, updatedAt?: any | null, translationKey: { __typename?: 'TranslationKey', id: number, key: string }, userCreated?: { __typename?: 'User', id: number, username: string, role?: { __typename?: 'Role', id: number, title: string } | null } | null, userUpdated?: { __typename?: 'User', id: number, username: string, role?: { __typename?: 'Role', id: number, title: string } | null } | null }> };

export type GetPermissionQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetPermissionQuery = { __typename?: 'Query', permission: { __typename?: 'Permission', id: number, title: string, available: boolean, createdAt: any, updatedAt?: any | null, translationKey: { __typename?: 'TranslationKey', id: number, key: string }, userCreated?: { __typename?: 'User', id: number, username: string, role?: { __typename?: 'Role', id: number, title: string } | null } | null, userUpdated?: { __typename?: 'User', id: number, username: string, role?: { __typename?: 'Role', id: number, title: string } | null } | null } };

export type GetRolesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRolesQuery = { __typename?: 'Query', roles: Array<{ __typename?: 'Role', id: number, title: string, available: boolean, createdAt: any, updatedAt?: any | null, translationKey: { __typename?: 'TranslationKey', id: number, key: string }, userCreated?: { __typename?: 'User', id: number, username: string, role?: { __typename?: 'Role', id: number, title: string } | null } | null, userUpdated?: { __typename?: 'User', id: number, username: string, role?: { __typename?: 'Role', id: number, title: string } | null } | null }> };

export type GetRoleQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetRoleQuery = { __typename?: 'Query', role: { __typename?: 'Role', id: number, title: string, available: boolean, createdAt: any, updatedAt?: any | null, translationKey: { __typename?: 'TranslationKey', id: number, key: string }, userCreated?: { __typename?: 'User', id: number, username: string, role?: { __typename?: 'Role', id: number, title: string } | null } | null, userUpdated?: { __typename?: 'User', id: number, username: string, role?: { __typename?: 'Role', id: number, title: string } | null } | null } };

export type GetTranslationKeysQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTranslationKeysQuery = { __typename?: 'Query', translationKeys: Array<{ __typename?: 'TranslationKey', id: number, key: string }> };

export type GetTranslationKeyQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetTranslationKeyQuery = { __typename?: 'Query', translationKey: { __typename?: 'TranslationKey', id: number, key: string } };

export type GetTranslationLanguagesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTranslationLanguagesQuery = { __typename?: 'Query', translationLanguages: Array<{ __typename?: 'TranslationLanguage', id: number, code: string, name: string }> };

export type GetTranslationLanguageQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetTranslationLanguageQuery = { __typename?: 'Query', translationLanguage: { __typename?: 'TranslationLanguage', id: number, code: string, name: string } };

export type GetTranslationValuesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTranslationValuesQuery = { __typename?: 'Query', translationValues: Array<{ __typename?: 'TranslationValue', id: number, value: string, translationKey: { __typename?: 'TranslationKey', id: number, key: string }, translationLanguage: { __typename?: 'TranslationLanguage', id: number, code: string, name: string } }> };

export type GetTranslationValueQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetTranslationValueQuery = { __typename?: 'Query', translationValue: { __typename?: 'TranslationValue', id: number, value: string, translationKey: { __typename?: 'TranslationKey', id: number, key: string }, translationLanguage: { __typename?: 'TranslationLanguage', id: number, code: string, name: string } } };

export type GetUsersQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetUsersQuery = { __typename?: 'Query', users: Array<{ __typename?: 'User', id: number, username: string, available: boolean, createdAt: any, updatedAt?: any | null, role?: { __typename?: 'Role', id: number, title: string } | null }> };

export type GetUserQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetUserQuery = { __typename?: 'Query', user: { __typename?: 'User', id: number, username: string, available: boolean, createdAt: any, updatedAt?: any | null, role?: { __typename?: 'Role', id: number, title: string } | null } };

export type LoginMutationVariables = Exact<{
  data: UserLoginInput;
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'LoginResponse', success: boolean, message?: string | null, token?: string | null } };


export const CreateConfigurationDocument = gql`
    mutation CreateConfiguration($data: ConfigurationCreateInput!) {
  createConfiguration(data: $data) {
    id
    title
    description
    value
    available
    userCreated {
      id
      role {
        id
        title
      }
      username
    }
    createdAt
    userUpdated {
      id
      role {
        id
        title
      }
      username
    }
    updatedAt
  }
}
    `;
export type CreateConfigurationMutationFn = Apollo.MutationFunction<CreateConfigurationMutation, CreateConfigurationMutationVariables>;

/**
 * __useCreateConfigurationMutation__
 *
 * To run a mutation, you first call `useCreateConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConfigurationMutation, { data, loading, error }] = useCreateConfigurationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<CreateConfigurationMutation, CreateConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateConfigurationMutation, CreateConfigurationMutationVariables>(CreateConfigurationDocument, options);
      }
export type CreateConfigurationMutationHookResult = ReturnType<typeof useCreateConfigurationMutation>;
export type CreateConfigurationMutationResult = Apollo.MutationResult<CreateConfigurationMutation>;
export type CreateConfigurationMutationOptions = Apollo.BaseMutationOptions<CreateConfigurationMutation, CreateConfigurationMutationVariables>;
export const UpdateConfigurationDocument = gql`
    mutation UpdateConfiguration($data: ConfigurationUpdateInput!) {
  updateConfiguration(data: $data) {
    id
    title
    description
    value
    available
    userCreated {
      id
      role {
        id
        title
      }
      username
    }
    createdAt
    userUpdated {
      id
      role {
        id
        title
      }
      username
    }
    updatedAt
  }
}
    `;
export type UpdateConfigurationMutationFn = Apollo.MutationFunction<UpdateConfigurationMutation, UpdateConfigurationMutationVariables>;

/**
 * __useUpdateConfigurationMutation__
 *
 * To run a mutation, you first call `useUpdateConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConfigurationMutation, { data, loading, error }] = useUpdateConfigurationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateConfigurationMutation, UpdateConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateConfigurationMutation, UpdateConfigurationMutationVariables>(UpdateConfigurationDocument, options);
      }
export type UpdateConfigurationMutationHookResult = ReturnType<typeof useUpdateConfigurationMutation>;
export type UpdateConfigurationMutationResult = Apollo.MutationResult<UpdateConfigurationMutation>;
export type UpdateConfigurationMutationOptions = Apollo.BaseMutationOptions<UpdateConfigurationMutation, UpdateConfigurationMutationVariables>;
export const DeleteConfigurationDocument = gql`
    mutation DeleteConfiguration($data: ConfigurationDeleteInput!) {
  deleteConfiguration(data: $data) {
    id
    title
    description
    value
    available
    userCreated {
      id
      role {
        id
        title
      }
      username
    }
    createdAt
    userUpdated {
      id
      role {
        id
        title
      }
      username
    }
    updatedAt
  }
}
    `;
export type DeleteConfigurationMutationFn = Apollo.MutationFunction<DeleteConfigurationMutation, DeleteConfigurationMutationVariables>;

/**
 * __useDeleteConfigurationMutation__
 *
 * To run a mutation, you first call `useDeleteConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteConfigurationMutation, { data, loading, error }] = useDeleteConfigurationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteConfigurationMutation, DeleteConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteConfigurationMutation, DeleteConfigurationMutationVariables>(DeleteConfigurationDocument, options);
      }
export type DeleteConfigurationMutationHookResult = ReturnType<typeof useDeleteConfigurationMutation>;
export type DeleteConfigurationMutationResult = Apollo.MutationResult<DeleteConfigurationMutation>;
export type DeleteConfigurationMutationOptions = Apollo.BaseMutationOptions<DeleteConfigurationMutation, DeleteConfigurationMutationVariables>;
export const CreateLogDocument = gql`
    mutation CreateLog($data: LogCreateInput!) {
  createLog(data: $data) {
    id
    message
  }
}
    `;
export type CreateLogMutationFn = Apollo.MutationFunction<CreateLogMutation, CreateLogMutationVariables>;

/**
 * __useCreateLogMutation__
 *
 * To run a mutation, you first call `useCreateLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLogMutation, { data, loading, error }] = useCreateLogMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateLogMutation(baseOptions?: Apollo.MutationHookOptions<CreateLogMutation, CreateLogMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLogMutation, CreateLogMutationVariables>(CreateLogDocument, options);
      }
export type CreateLogMutationHookResult = ReturnType<typeof useCreateLogMutation>;
export type CreateLogMutationResult = Apollo.MutationResult<CreateLogMutation>;
export type CreateLogMutationOptions = Apollo.BaseMutationOptions<CreateLogMutation, CreateLogMutationVariables>;
export const UpdateLogDocument = gql`
    mutation UpdateLog($data: LogUpdateInput!) {
  updateLog(data: $data) {
    id
    message
  }
}
    `;
export type UpdateLogMutationFn = Apollo.MutationFunction<UpdateLogMutation, UpdateLogMutationVariables>;

/**
 * __useUpdateLogMutation__
 *
 * To run a mutation, you first call `useUpdateLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLogMutation, { data, loading, error }] = useUpdateLogMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateLogMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLogMutation, UpdateLogMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLogMutation, UpdateLogMutationVariables>(UpdateLogDocument, options);
      }
export type UpdateLogMutationHookResult = ReturnType<typeof useUpdateLogMutation>;
export type UpdateLogMutationResult = Apollo.MutationResult<UpdateLogMutation>;
export type UpdateLogMutationOptions = Apollo.BaseMutationOptions<UpdateLogMutation, UpdateLogMutationVariables>;
export const DeleteLogDocument = gql`
    mutation DeleteLog($data: LogDeleteInput!) {
  deleteLog(data: $data) {
    id
    message
  }
}
    `;
export type DeleteLogMutationFn = Apollo.MutationFunction<DeleteLogMutation, DeleteLogMutationVariables>;

/**
 * __useDeleteLogMutation__
 *
 * To run a mutation, you first call `useDeleteLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLogMutation, { data, loading, error }] = useDeleteLogMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteLogMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLogMutation, DeleteLogMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLogMutation, DeleteLogMutationVariables>(DeleteLogDocument, options);
      }
export type DeleteLogMutationHookResult = ReturnType<typeof useDeleteLogMutation>;
export type DeleteLogMutationResult = Apollo.MutationResult<DeleteLogMutation>;
export type DeleteLogMutationOptions = Apollo.BaseMutationOptions<DeleteLogMutation, DeleteLogMutationVariables>;
export const CreateModuleRolePermissionDocument = gql`
    mutation CreateModuleRolePermission($data: ModuleRolePermissionCreateInput!) {
  createModuleRolePermission(data: $data) {
    id
    module {
      title
    }
    role {
      title
    }
    permission {
      title
    }
    userCreated {
      id
      role {
        id
        title
      }
      username
    }
    createdAt
    userUpdated {
      id
      role {
        id
        title
      }
      username
    }
    updatedAt
  }
}
    `;
export type CreateModuleRolePermissionMutationFn = Apollo.MutationFunction<CreateModuleRolePermissionMutation, CreateModuleRolePermissionMutationVariables>;

/**
 * __useCreateModuleRolePermissionMutation__
 *
 * To run a mutation, you first call `useCreateModuleRolePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateModuleRolePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createModuleRolePermissionMutation, { data, loading, error }] = useCreateModuleRolePermissionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateModuleRolePermissionMutation(baseOptions?: Apollo.MutationHookOptions<CreateModuleRolePermissionMutation, CreateModuleRolePermissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateModuleRolePermissionMutation, CreateModuleRolePermissionMutationVariables>(CreateModuleRolePermissionDocument, options);
      }
export type CreateModuleRolePermissionMutationHookResult = ReturnType<typeof useCreateModuleRolePermissionMutation>;
export type CreateModuleRolePermissionMutationResult = Apollo.MutationResult<CreateModuleRolePermissionMutation>;
export type CreateModuleRolePermissionMutationOptions = Apollo.BaseMutationOptions<CreateModuleRolePermissionMutation, CreateModuleRolePermissionMutationVariables>;
export const UpdateModuleRolePermissionDocument = gql`
    mutation UpdateModuleRolePermission($data: ModuleRolePermissionUpdateInput!) {
  updateModuleRolePermission(data: $data) {
    id
    module {
      title
    }
    role {
      title
    }
    permission {
      title
    }
    userCreated {
      id
      role {
        id
        title
      }
      username
    }
    createdAt
    userUpdated {
      id
      role {
        id
        title
      }
      username
    }
    updatedAt
  }
}
    `;
export type UpdateModuleRolePermissionMutationFn = Apollo.MutationFunction<UpdateModuleRolePermissionMutation, UpdateModuleRolePermissionMutationVariables>;

/**
 * __useUpdateModuleRolePermissionMutation__
 *
 * To run a mutation, you first call `useUpdateModuleRolePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateModuleRolePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateModuleRolePermissionMutation, { data, loading, error }] = useUpdateModuleRolePermissionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateModuleRolePermissionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateModuleRolePermissionMutation, UpdateModuleRolePermissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateModuleRolePermissionMutation, UpdateModuleRolePermissionMutationVariables>(UpdateModuleRolePermissionDocument, options);
      }
export type UpdateModuleRolePermissionMutationHookResult = ReturnType<typeof useUpdateModuleRolePermissionMutation>;
export type UpdateModuleRolePermissionMutationResult = Apollo.MutationResult<UpdateModuleRolePermissionMutation>;
export type UpdateModuleRolePermissionMutationOptions = Apollo.BaseMutationOptions<UpdateModuleRolePermissionMutation, UpdateModuleRolePermissionMutationVariables>;
export const DeleteModuleRolePermissionDocument = gql`
    mutation DeleteModuleRolePermission($data: ModuleRolePermissionDeleteInput!) {
  deleteModuleRolePermission(data: $data) {
    id
    module {
      title
    }
    role {
      title
    }
    permission {
      title
    }
    userCreated {
      id
      role {
        id
        title
      }
      username
    }
    createdAt
    userUpdated {
      id
      role {
        id
        title
      }
      username
    }
    updatedAt
  }
}
    `;
export type DeleteModuleRolePermissionMutationFn = Apollo.MutationFunction<DeleteModuleRolePermissionMutation, DeleteModuleRolePermissionMutationVariables>;

/**
 * __useDeleteModuleRolePermissionMutation__
 *
 * To run a mutation, you first call `useDeleteModuleRolePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteModuleRolePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteModuleRolePermissionMutation, { data, loading, error }] = useDeleteModuleRolePermissionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteModuleRolePermissionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteModuleRolePermissionMutation, DeleteModuleRolePermissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteModuleRolePermissionMutation, DeleteModuleRolePermissionMutationVariables>(DeleteModuleRolePermissionDocument, options);
      }
export type DeleteModuleRolePermissionMutationHookResult = ReturnType<typeof useDeleteModuleRolePermissionMutation>;
export type DeleteModuleRolePermissionMutationResult = Apollo.MutationResult<DeleteModuleRolePermissionMutation>;
export type DeleteModuleRolePermissionMutationOptions = Apollo.BaseMutationOptions<DeleteModuleRolePermissionMutation, DeleteModuleRolePermissionMutationVariables>;
export const CreateModuleDocument = gql`
    mutation CreateModule($data: ModuleCreateInput!) {
  createModule(data: $data) {
    id
    order
    title
    friendlyUrl
    icon
    superAdmin
    available
    translationKey {
      key
    }
    userCreated {
      id
      role {
        id
        title
      }
      username
    }
    createdAt
    userUpdated {
      id
      role {
        id
        title
      }
      username
    }
    updatedAt
  }
}
    `;
export type CreateModuleMutationFn = Apollo.MutationFunction<CreateModuleMutation, CreateModuleMutationVariables>;

/**
 * __useCreateModuleMutation__
 *
 * To run a mutation, you first call `useCreateModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createModuleMutation, { data, loading, error }] = useCreateModuleMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateModuleMutation(baseOptions?: Apollo.MutationHookOptions<CreateModuleMutation, CreateModuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateModuleMutation, CreateModuleMutationVariables>(CreateModuleDocument, options);
      }
export type CreateModuleMutationHookResult = ReturnType<typeof useCreateModuleMutation>;
export type CreateModuleMutationResult = Apollo.MutationResult<CreateModuleMutation>;
export type CreateModuleMutationOptions = Apollo.BaseMutationOptions<CreateModuleMutation, CreateModuleMutationVariables>;
export const UpdateModuleDocument = gql`
    mutation UpdateModule($data: ModuleUpdateInput!) {
  updateModule(data: $data) {
    id
    order
    title
    friendlyUrl
    icon
    superAdmin
    available
    translationKey {
      key
    }
    userCreated {
      id
      role {
        id
        title
      }
      username
    }
    createdAt
    userUpdated {
      id
      role {
        id
        title
      }
      username
    }
    updatedAt
  }
}
    `;
export type UpdateModuleMutationFn = Apollo.MutationFunction<UpdateModuleMutation, UpdateModuleMutationVariables>;

/**
 * __useUpdateModuleMutation__
 *
 * To run a mutation, you first call `useUpdateModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateModuleMutation, { data, loading, error }] = useUpdateModuleMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateModuleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateModuleMutation, UpdateModuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateModuleMutation, UpdateModuleMutationVariables>(UpdateModuleDocument, options);
      }
export type UpdateModuleMutationHookResult = ReturnType<typeof useUpdateModuleMutation>;
export type UpdateModuleMutationResult = Apollo.MutationResult<UpdateModuleMutation>;
export type UpdateModuleMutationOptions = Apollo.BaseMutationOptions<UpdateModuleMutation, UpdateModuleMutationVariables>;
export const DeleteModuleDocument = gql`
    mutation DeleteModule($data: ModuleDeleteInput!) {
  deleteModule(data: $data) {
    id
    order
    title
    friendlyUrl
    icon
    superAdmin
    available
    translationKey {
      key
    }
    userCreated {
      id
      role {
        id
        title
      }
      username
    }
    createdAt
    userUpdated {
      id
      role {
        id
        title
      }
      username
    }
    updatedAt
  }
}
    `;
export type DeleteModuleMutationFn = Apollo.MutationFunction<DeleteModuleMutation, DeleteModuleMutationVariables>;

/**
 * __useDeleteModuleMutation__
 *
 * To run a mutation, you first call `useDeleteModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteModuleMutation, { data, loading, error }] = useDeleteModuleMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteModuleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteModuleMutation, DeleteModuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteModuleMutation, DeleteModuleMutationVariables>(DeleteModuleDocument, options);
      }
export type DeleteModuleMutationHookResult = ReturnType<typeof useDeleteModuleMutation>;
export type DeleteModuleMutationResult = Apollo.MutationResult<DeleteModuleMutation>;
export type DeleteModuleMutationOptions = Apollo.BaseMutationOptions<DeleteModuleMutation, DeleteModuleMutationVariables>;
export const CreatePermissionDocument = gql`
    mutation CreatePermission($data: PermissionCreateInput!) {
  createPermission(data: $data) {
    id
    title
    available
    translationKey {
      key
    }
    userCreated {
      id
      role {
        id
        title
      }
      username
    }
    createdAt
    userUpdated {
      id
      role {
        id
        title
      }
      username
    }
    updatedAt
  }
}
    `;
export type CreatePermissionMutationFn = Apollo.MutationFunction<CreatePermissionMutation, CreatePermissionMutationVariables>;

/**
 * __useCreatePermissionMutation__
 *
 * To run a mutation, you first call `useCreatePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPermissionMutation, { data, loading, error }] = useCreatePermissionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreatePermissionMutation(baseOptions?: Apollo.MutationHookOptions<CreatePermissionMutation, CreatePermissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePermissionMutation, CreatePermissionMutationVariables>(CreatePermissionDocument, options);
      }
export type CreatePermissionMutationHookResult = ReturnType<typeof useCreatePermissionMutation>;
export type CreatePermissionMutationResult = Apollo.MutationResult<CreatePermissionMutation>;
export type CreatePermissionMutationOptions = Apollo.BaseMutationOptions<CreatePermissionMutation, CreatePermissionMutationVariables>;
export const UpdatePermissionDocument = gql`
    mutation UpdatePermission($data: PermissionUpdateInput!) {
  updatePermission(data: $data) {
    id
    title
    available
    translationKey {
      key
    }
    userCreated {
      id
      role {
        id
        title
      }
      username
    }
    createdAt
    userUpdated {
      id
      role {
        id
        title
      }
      username
    }
    updatedAt
  }
}
    `;
export type UpdatePermissionMutationFn = Apollo.MutationFunction<UpdatePermissionMutation, UpdatePermissionMutationVariables>;

/**
 * __useUpdatePermissionMutation__
 *
 * To run a mutation, you first call `useUpdatePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePermissionMutation, { data, loading, error }] = useUpdatePermissionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePermissionMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePermissionMutation, UpdatePermissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePermissionMutation, UpdatePermissionMutationVariables>(UpdatePermissionDocument, options);
      }
export type UpdatePermissionMutationHookResult = ReturnType<typeof useUpdatePermissionMutation>;
export type UpdatePermissionMutationResult = Apollo.MutationResult<UpdatePermissionMutation>;
export type UpdatePermissionMutationOptions = Apollo.BaseMutationOptions<UpdatePermissionMutation, UpdatePermissionMutationVariables>;
export const DeletePermissionDocument = gql`
    mutation DeletePermission($data: PermissionDeleteInput!) {
  deletePermission(data: $data) {
    id
    title
    available
    translationKey {
      key
    }
    userCreated {
      id
      role {
        id
        title
      }
      username
    }
    createdAt
    userUpdated {
      id
      role {
        id
        title
      }
      username
    }
    updatedAt
  }
}
    `;
export type DeletePermissionMutationFn = Apollo.MutationFunction<DeletePermissionMutation, DeletePermissionMutationVariables>;

/**
 * __useDeletePermissionMutation__
 *
 * To run a mutation, you first call `useDeletePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePermissionMutation, { data, loading, error }] = useDeletePermissionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeletePermissionMutation(baseOptions?: Apollo.MutationHookOptions<DeletePermissionMutation, DeletePermissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePermissionMutation, DeletePermissionMutationVariables>(DeletePermissionDocument, options);
      }
export type DeletePermissionMutationHookResult = ReturnType<typeof useDeletePermissionMutation>;
export type DeletePermissionMutationResult = Apollo.MutationResult<DeletePermissionMutation>;
export type DeletePermissionMutationOptions = Apollo.BaseMutationOptions<DeletePermissionMutation, DeletePermissionMutationVariables>;
export const CreateRoleDocument = gql`
    mutation CreateRole($data: RoleCreateInput!) {
  createRole(data: $data) {
    id
    title
    available
    translationKey {
      key
    }
    userCreated {
      id
      role {
        id
        title
      }
      username
    }
    createdAt
    userUpdated {
      id
      role {
        id
        title
      }
      username
    }
    updatedAt
  }
}
    `;
export type CreateRoleMutationFn = Apollo.MutationFunction<CreateRoleMutation, CreateRoleMutationVariables>;

/**
 * __useCreateRoleMutation__
 *
 * To run a mutation, you first call `useCreateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoleMutation, { data, loading, error }] = useCreateRoleMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateRoleMutation(baseOptions?: Apollo.MutationHookOptions<CreateRoleMutation, CreateRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRoleMutation, CreateRoleMutationVariables>(CreateRoleDocument, options);
      }
export type CreateRoleMutationHookResult = ReturnType<typeof useCreateRoleMutation>;
export type CreateRoleMutationResult = Apollo.MutationResult<CreateRoleMutation>;
export type CreateRoleMutationOptions = Apollo.BaseMutationOptions<CreateRoleMutation, CreateRoleMutationVariables>;
export const UpdateRoleDocument = gql`
    mutation UpdateRole($data: RoleUpdateInput!) {
  updateRole(data: $data) {
    id
    title
    available
    translationKey {
      key
    }
    userCreated {
      id
      role {
        id
        title
      }
      username
    }
    createdAt
    userUpdated {
      id
      role {
        id
        title
      }
      username
    }
    updatedAt
  }
}
    `;
export type UpdateRoleMutationFn = Apollo.MutationFunction<UpdateRoleMutation, UpdateRoleMutationVariables>;

/**
 * __useUpdateRoleMutation__
 *
 * To run a mutation, you first call `useUpdateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoleMutation, { data, loading, error }] = useUpdateRoleMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateRoleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRoleMutation, UpdateRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRoleMutation, UpdateRoleMutationVariables>(UpdateRoleDocument, options);
      }
export type UpdateRoleMutationHookResult = ReturnType<typeof useUpdateRoleMutation>;
export type UpdateRoleMutationResult = Apollo.MutationResult<UpdateRoleMutation>;
export type UpdateRoleMutationOptions = Apollo.BaseMutationOptions<UpdateRoleMutation, UpdateRoleMutationVariables>;
export const DeleteRoleDocument = gql`
    mutation DeleteRole($data: RoleDeleteInput!) {
  deleteRole(data: $data) {
    id
    title
    available
    translationKey {
      key
    }
    userCreated {
      id
      role {
        id
        title
      }
      username
    }
    createdAt
    userUpdated {
      id
      role {
        id
        title
      }
      username
    }
    updatedAt
  }
}
    `;
export type DeleteRoleMutationFn = Apollo.MutationFunction<DeleteRoleMutation, DeleteRoleMutationVariables>;

/**
 * __useDeleteRoleMutation__
 *
 * To run a mutation, you first call `useDeleteRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRoleMutation, { data, loading, error }] = useDeleteRoleMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteRoleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRoleMutation, DeleteRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRoleMutation, DeleteRoleMutationVariables>(DeleteRoleDocument, options);
      }
export type DeleteRoleMutationHookResult = ReturnType<typeof useDeleteRoleMutation>;
export type DeleteRoleMutationResult = Apollo.MutationResult<DeleteRoleMutation>;
export type DeleteRoleMutationOptions = Apollo.BaseMutationOptions<DeleteRoleMutation, DeleteRoleMutationVariables>;
export const CreateTranslationKeyDocument = gql`
    mutation CreateTranslationKey($data: TranslationKeyCreateInput!) {
  createTranslationKey(data: $data) {
    id
    key
  }
}
    `;
export type CreateTranslationKeyMutationFn = Apollo.MutationFunction<CreateTranslationKeyMutation, CreateTranslationKeyMutationVariables>;

/**
 * __useCreateTranslationKeyMutation__
 *
 * To run a mutation, you first call `useCreateTranslationKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTranslationKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTranslationKeyMutation, { data, loading, error }] = useCreateTranslationKeyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTranslationKeyMutation(baseOptions?: Apollo.MutationHookOptions<CreateTranslationKeyMutation, CreateTranslationKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTranslationKeyMutation, CreateTranslationKeyMutationVariables>(CreateTranslationKeyDocument, options);
      }
export type CreateTranslationKeyMutationHookResult = ReturnType<typeof useCreateTranslationKeyMutation>;
export type CreateTranslationKeyMutationResult = Apollo.MutationResult<CreateTranslationKeyMutation>;
export type CreateTranslationKeyMutationOptions = Apollo.BaseMutationOptions<CreateTranslationKeyMutation, CreateTranslationKeyMutationVariables>;
export const UpdateTranslationKeyDocument = gql`
    mutation UpdateTranslationKey($data: TranslationKeyUpdateInput!) {
  updateTranslationKey(data: $data) {
    id
    key
  }
}
    `;
export type UpdateTranslationKeyMutationFn = Apollo.MutationFunction<UpdateTranslationKeyMutation, UpdateTranslationKeyMutationVariables>;

/**
 * __useUpdateTranslationKeyMutation__
 *
 * To run a mutation, you first call `useUpdateTranslationKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTranslationKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTranslationKeyMutation, { data, loading, error }] = useUpdateTranslationKeyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTranslationKeyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTranslationKeyMutation, UpdateTranslationKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTranslationKeyMutation, UpdateTranslationKeyMutationVariables>(UpdateTranslationKeyDocument, options);
      }
export type UpdateTranslationKeyMutationHookResult = ReturnType<typeof useUpdateTranslationKeyMutation>;
export type UpdateTranslationKeyMutationResult = Apollo.MutationResult<UpdateTranslationKeyMutation>;
export type UpdateTranslationKeyMutationOptions = Apollo.BaseMutationOptions<UpdateTranslationKeyMutation, UpdateTranslationKeyMutationVariables>;
export const DeleteTranslationKeyDocument = gql`
    mutation DeleteTranslationKey($data: TranslationKeyDeleteInput!) {
  deleteTranslationKey(data: $data) {
    id
    key
  }
}
    `;
export type DeleteTranslationKeyMutationFn = Apollo.MutationFunction<DeleteTranslationKeyMutation, DeleteTranslationKeyMutationVariables>;

/**
 * __useDeleteTranslationKeyMutation__
 *
 * To run a mutation, you first call `useDeleteTranslationKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTranslationKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTranslationKeyMutation, { data, loading, error }] = useDeleteTranslationKeyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteTranslationKeyMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTranslationKeyMutation, DeleteTranslationKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTranslationKeyMutation, DeleteTranslationKeyMutationVariables>(DeleteTranslationKeyDocument, options);
      }
export type DeleteTranslationKeyMutationHookResult = ReturnType<typeof useDeleteTranslationKeyMutation>;
export type DeleteTranslationKeyMutationResult = Apollo.MutationResult<DeleteTranslationKeyMutation>;
export type DeleteTranslationKeyMutationOptions = Apollo.BaseMutationOptions<DeleteTranslationKeyMutation, DeleteTranslationKeyMutationVariables>;
export const CreateTranslationLanguageDocument = gql`
    mutation CreateTranslationLanguage($data: TranslationLanguageCreateInput!) {
  createTranslationLanguage(data: $data) {
    id
    code
    name
  }
}
    `;
export type CreateTranslationLanguageMutationFn = Apollo.MutationFunction<CreateTranslationLanguageMutation, CreateTranslationLanguageMutationVariables>;

/**
 * __useCreateTranslationLanguageMutation__
 *
 * To run a mutation, you first call `useCreateTranslationLanguageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTranslationLanguageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTranslationLanguageMutation, { data, loading, error }] = useCreateTranslationLanguageMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTranslationLanguageMutation(baseOptions?: Apollo.MutationHookOptions<CreateTranslationLanguageMutation, CreateTranslationLanguageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTranslationLanguageMutation, CreateTranslationLanguageMutationVariables>(CreateTranslationLanguageDocument, options);
      }
export type CreateTranslationLanguageMutationHookResult = ReturnType<typeof useCreateTranslationLanguageMutation>;
export type CreateTranslationLanguageMutationResult = Apollo.MutationResult<CreateTranslationLanguageMutation>;
export type CreateTranslationLanguageMutationOptions = Apollo.BaseMutationOptions<CreateTranslationLanguageMutation, CreateTranslationLanguageMutationVariables>;
export const UpdateTranslationLanguageDocument = gql`
    mutation UpdateTranslationLanguage($data: TranslationLanguageUpdateInput!) {
  updateTranslationLanguage(data: $data) {
    id
    code
    name
  }
}
    `;
export type UpdateTranslationLanguageMutationFn = Apollo.MutationFunction<UpdateTranslationLanguageMutation, UpdateTranslationLanguageMutationVariables>;

/**
 * __useUpdateTranslationLanguageMutation__
 *
 * To run a mutation, you first call `useUpdateTranslationLanguageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTranslationLanguageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTranslationLanguageMutation, { data, loading, error }] = useUpdateTranslationLanguageMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTranslationLanguageMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTranslationLanguageMutation, UpdateTranslationLanguageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTranslationLanguageMutation, UpdateTranslationLanguageMutationVariables>(UpdateTranslationLanguageDocument, options);
      }
export type UpdateTranslationLanguageMutationHookResult = ReturnType<typeof useUpdateTranslationLanguageMutation>;
export type UpdateTranslationLanguageMutationResult = Apollo.MutationResult<UpdateTranslationLanguageMutation>;
export type UpdateTranslationLanguageMutationOptions = Apollo.BaseMutationOptions<UpdateTranslationLanguageMutation, UpdateTranslationLanguageMutationVariables>;
export const DeleteTranslationLanguageDocument = gql`
    mutation DeleteTranslationLanguage($data: TranslationLanguageDeleteInput!) {
  deleteTranslationLanguage(data: $data) {
    id
    code
    name
  }
}
    `;
export type DeleteTranslationLanguageMutationFn = Apollo.MutationFunction<DeleteTranslationLanguageMutation, DeleteTranslationLanguageMutationVariables>;

/**
 * __useDeleteTranslationLanguageMutation__
 *
 * To run a mutation, you first call `useDeleteTranslationLanguageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTranslationLanguageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTranslationLanguageMutation, { data, loading, error }] = useDeleteTranslationLanguageMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteTranslationLanguageMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTranslationLanguageMutation, DeleteTranslationLanguageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTranslationLanguageMutation, DeleteTranslationLanguageMutationVariables>(DeleteTranslationLanguageDocument, options);
      }
export type DeleteTranslationLanguageMutationHookResult = ReturnType<typeof useDeleteTranslationLanguageMutation>;
export type DeleteTranslationLanguageMutationResult = Apollo.MutationResult<DeleteTranslationLanguageMutation>;
export type DeleteTranslationLanguageMutationOptions = Apollo.BaseMutationOptions<DeleteTranslationLanguageMutation, DeleteTranslationLanguageMutationVariables>;
export const CreateTranslationValueDocument = gql`
    mutation CreateTranslationValue($data: TranslationValueCreateInput!) {
  createTranslationValue(data: $data) {
    id
    value
    translationKey {
      id
      key
    }
    translationLanguage {
      id
      code
      name
    }
  }
}
    `;
export type CreateTranslationValueMutationFn = Apollo.MutationFunction<CreateTranslationValueMutation, CreateTranslationValueMutationVariables>;

/**
 * __useCreateTranslationValueMutation__
 *
 * To run a mutation, you first call `useCreateTranslationValueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTranslationValueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTranslationValueMutation, { data, loading, error }] = useCreateTranslationValueMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTranslationValueMutation(baseOptions?: Apollo.MutationHookOptions<CreateTranslationValueMutation, CreateTranslationValueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTranslationValueMutation, CreateTranslationValueMutationVariables>(CreateTranslationValueDocument, options);
      }
export type CreateTranslationValueMutationHookResult = ReturnType<typeof useCreateTranslationValueMutation>;
export type CreateTranslationValueMutationResult = Apollo.MutationResult<CreateTranslationValueMutation>;
export type CreateTranslationValueMutationOptions = Apollo.BaseMutationOptions<CreateTranslationValueMutation, CreateTranslationValueMutationVariables>;
export const UpdateTranslationValueDocument = gql`
    mutation UpdateTranslationValue($data: TranslationValueUpdateInput!) {
  updateTranslationValue(data: $data) {
    id
    value
    translationKey {
      id
      key
    }
    translationLanguage {
      id
      code
      name
    }
  }
}
    `;
export type UpdateTranslationValueMutationFn = Apollo.MutationFunction<UpdateTranslationValueMutation, UpdateTranslationValueMutationVariables>;

/**
 * __useUpdateTranslationValueMutation__
 *
 * To run a mutation, you first call `useUpdateTranslationValueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTranslationValueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTranslationValueMutation, { data, loading, error }] = useUpdateTranslationValueMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTranslationValueMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTranslationValueMutation, UpdateTranslationValueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTranslationValueMutation, UpdateTranslationValueMutationVariables>(UpdateTranslationValueDocument, options);
      }
export type UpdateTranslationValueMutationHookResult = ReturnType<typeof useUpdateTranslationValueMutation>;
export type UpdateTranslationValueMutationResult = Apollo.MutationResult<UpdateTranslationValueMutation>;
export type UpdateTranslationValueMutationOptions = Apollo.BaseMutationOptions<UpdateTranslationValueMutation, UpdateTranslationValueMutationVariables>;
export const DeleteTranslationValueDocument = gql`
    mutation DeleteTranslationValue($data: TranslationValueDeleteInput!) {
  deleteTranslationValue(data: $data) {
    id
    value
    translationKey {
      id
      key
    }
    translationLanguage {
      id
      code
      name
    }
  }
}
    `;
export type DeleteTranslationValueMutationFn = Apollo.MutationFunction<DeleteTranslationValueMutation, DeleteTranslationValueMutationVariables>;

/**
 * __useDeleteTranslationValueMutation__
 *
 * To run a mutation, you first call `useDeleteTranslationValueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTranslationValueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTranslationValueMutation, { data, loading, error }] = useDeleteTranslationValueMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteTranslationValueMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTranslationValueMutation, DeleteTranslationValueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTranslationValueMutation, DeleteTranslationValueMutationVariables>(DeleteTranslationValueDocument, options);
      }
export type DeleteTranslationValueMutationHookResult = ReturnType<typeof useDeleteTranslationValueMutation>;
export type DeleteTranslationValueMutationResult = Apollo.MutationResult<DeleteTranslationValueMutation>;
export type DeleteTranslationValueMutationOptions = Apollo.BaseMutationOptions<DeleteTranslationValueMutation, DeleteTranslationValueMutationVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($data: UserCreateInput!) {
  createUser(data: $data) {
    id
    role {
      id
      title
    }
    username
    password
    available
    createdAt
    updatedAt
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($data: UserUpdateInput!) {
  updateUser(data: $data) {
    id
    role {
      id
      title
    }
    username
    password
    available
    createdAt
    updatedAt
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($data: UserDeleteInput!) {
  deleteUser(data: $data) {
    id
    role {
      id
      title
    }
    username
    password
    available
    createdAt
    updatedAt
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const GetConfigurationsDocument = gql`
    query GetConfigurations {
  configurations {
    id
    title
    description
    value
    available
    userCreated {
      id
      role {
        id
        title
      }
      username
    }
    createdAt
    userUpdated {
      id
      role {
        id
        title
      }
      username
    }
    updatedAt
  }
}
    `;

/**
 * __useGetConfigurationsQuery__
 *
 * To run a query within a React component, call `useGetConfigurationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConfigurationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConfigurationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetConfigurationsQuery(baseOptions?: Apollo.QueryHookOptions<GetConfigurationsQuery, GetConfigurationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetConfigurationsQuery, GetConfigurationsQueryVariables>(GetConfigurationsDocument, options);
      }
export function useGetConfigurationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConfigurationsQuery, GetConfigurationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetConfigurationsQuery, GetConfigurationsQueryVariables>(GetConfigurationsDocument, options);
        }
export type GetConfigurationsQueryHookResult = ReturnType<typeof useGetConfigurationsQuery>;
export type GetConfigurationsLazyQueryHookResult = ReturnType<typeof useGetConfigurationsLazyQuery>;
export type GetConfigurationsQueryResult = Apollo.QueryResult<GetConfigurationsQuery, GetConfigurationsQueryVariables>;
export const GetConfigurationDocument = gql`
    query GetConfiguration($id: Int) {
  configuration(id: $id) {
    id
    title
    description
    value
    available
    userCreated {
      id
      role {
        id
        title
      }
      username
    }
    createdAt
    userUpdated {
      id
      role {
        id
        title
      }
      username
    }
    updatedAt
  }
}
    `;

/**
 * __useGetConfigurationQuery__
 *
 * To run a query within a React component, call `useGetConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConfigurationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetConfigurationQuery(baseOptions?: Apollo.QueryHookOptions<GetConfigurationQuery, GetConfigurationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetConfigurationQuery, GetConfigurationQueryVariables>(GetConfigurationDocument, options);
      }
export function useGetConfigurationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConfigurationQuery, GetConfigurationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetConfigurationQuery, GetConfigurationQueryVariables>(GetConfigurationDocument, options);
        }
export type GetConfigurationQueryHookResult = ReturnType<typeof useGetConfigurationQuery>;
export type GetConfigurationLazyQueryHookResult = ReturnType<typeof useGetConfigurationLazyQuery>;
export type GetConfigurationQueryResult = Apollo.QueryResult<GetConfigurationQuery, GetConfigurationQueryVariables>;
export const GeoServerLayerUrlDocument = gql`
    query GeoServerLayerUrl($layerName: String!) {
  geoServerLayerUrl(layerName: $layerName)
}
    `;

/**
 * __useGeoServerLayerUrlQuery__
 *
 * To run a query within a React component, call `useGeoServerLayerUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGeoServerLayerUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGeoServerLayerUrlQuery({
 *   variables: {
 *      layerName: // value for 'layerName'
 *   },
 * });
 */
export function useGeoServerLayerUrlQuery(baseOptions: Apollo.QueryHookOptions<GeoServerLayerUrlQuery, GeoServerLayerUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GeoServerLayerUrlQuery, GeoServerLayerUrlQueryVariables>(GeoServerLayerUrlDocument, options);
      }
export function useGeoServerLayerUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GeoServerLayerUrlQuery, GeoServerLayerUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GeoServerLayerUrlQuery, GeoServerLayerUrlQueryVariables>(GeoServerLayerUrlDocument, options);
        }
export type GeoServerLayerUrlQueryHookResult = ReturnType<typeof useGeoServerLayerUrlQuery>;
export type GeoServerLayerUrlLazyQueryHookResult = ReturnType<typeof useGeoServerLayerUrlLazyQuery>;
export type GeoServerLayerUrlQueryResult = Apollo.QueryResult<GeoServerLayerUrlQuery, GeoServerLayerUrlQueryVariables>;
export const GeoServerLayersDocument = gql`
    query GeoServerLayers {
  geoServerLayers {
    name
    href
  }
}
    `;

/**
 * __useGeoServerLayersQuery__
 *
 * To run a query within a React component, call `useGeoServerLayersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGeoServerLayersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGeoServerLayersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGeoServerLayersQuery(baseOptions?: Apollo.QueryHookOptions<GeoServerLayersQuery, GeoServerLayersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GeoServerLayersQuery, GeoServerLayersQueryVariables>(GeoServerLayersDocument, options);
      }
export function useGeoServerLayersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GeoServerLayersQuery, GeoServerLayersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GeoServerLayersQuery, GeoServerLayersQueryVariables>(GeoServerLayersDocument, options);
        }
export type GeoServerLayersQueryHookResult = ReturnType<typeof useGeoServerLayersQuery>;
export type GeoServerLayersLazyQueryHookResult = ReturnType<typeof useGeoServerLayersLazyQuery>;
export type GeoServerLayersQueryResult = Apollo.QueryResult<GeoServerLayersQuery, GeoServerLayersQueryVariables>;
export const GetLogsDocument = gql`
    query GetLogs {
  logs {
    id
    message
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetLogsQuery__
 *
 * To run a query within a React component, call `useGetLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLogsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLogsQuery(baseOptions?: Apollo.QueryHookOptions<GetLogsQuery, GetLogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLogsQuery, GetLogsQueryVariables>(GetLogsDocument, options);
      }
export function useGetLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLogsQuery, GetLogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLogsQuery, GetLogsQueryVariables>(GetLogsDocument, options);
        }
export type GetLogsQueryHookResult = ReturnType<typeof useGetLogsQuery>;
export type GetLogsLazyQueryHookResult = ReturnType<typeof useGetLogsLazyQuery>;
export type GetLogsQueryResult = Apollo.QueryResult<GetLogsQuery, GetLogsQueryVariables>;
export const GetLogDocument = gql`
    query GetLog($id: Int) {
  log(id: $id) {
    id
    message
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetLogQuery__
 *
 * To run a query within a React component, call `useGetLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLogQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLogQuery(baseOptions?: Apollo.QueryHookOptions<GetLogQuery, GetLogQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLogQuery, GetLogQueryVariables>(GetLogDocument, options);
      }
export function useGetLogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLogQuery, GetLogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLogQuery, GetLogQueryVariables>(GetLogDocument, options);
        }
export type GetLogQueryHookResult = ReturnType<typeof useGetLogQuery>;
export type GetLogLazyQueryHookResult = ReturnType<typeof useGetLogLazyQuery>;
export type GetLogQueryResult = Apollo.QueryResult<GetLogQuery, GetLogQueryVariables>;
export const GetModuleRolePermissionsDocument = gql`
    query GetModuleRolePermissions {
  moduleRolePermissions {
    id
    module {
      title
    }
    role {
      title
    }
    permission {
      title
    }
    userCreated {
      id
      role {
        id
        title
      }
      username
    }
    createdAt
    userUpdated {
      id
      role {
        id
        title
      }
      username
    }
    updatedAt
  }
}
    `;

/**
 * __useGetModuleRolePermissionsQuery__
 *
 * To run a query within a React component, call `useGetModuleRolePermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModuleRolePermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModuleRolePermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetModuleRolePermissionsQuery(baseOptions?: Apollo.QueryHookOptions<GetModuleRolePermissionsQuery, GetModuleRolePermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetModuleRolePermissionsQuery, GetModuleRolePermissionsQueryVariables>(GetModuleRolePermissionsDocument, options);
      }
export function useGetModuleRolePermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetModuleRolePermissionsQuery, GetModuleRolePermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetModuleRolePermissionsQuery, GetModuleRolePermissionsQueryVariables>(GetModuleRolePermissionsDocument, options);
        }
export type GetModuleRolePermissionsQueryHookResult = ReturnType<typeof useGetModuleRolePermissionsQuery>;
export type GetModuleRolePermissionsLazyQueryHookResult = ReturnType<typeof useGetModuleRolePermissionsLazyQuery>;
export type GetModuleRolePermissionsQueryResult = Apollo.QueryResult<GetModuleRolePermissionsQuery, GetModuleRolePermissionsQueryVariables>;
export const GetModuleRolePermissionDocument = gql`
    query GetModuleRolePermission($id: Int) {
  moduleRolePermission(id: $id) {
    id
    module {
      title
    }
    role {
      title
    }
    permission {
      title
    }
    userCreated {
      id
      role {
        id
        title
      }
      username
    }
    createdAt
    userUpdated {
      id
      role {
        id
        title
      }
      username
    }
    updatedAt
  }
}
    `;

/**
 * __useGetModuleRolePermissionQuery__
 *
 * To run a query within a React component, call `useGetModuleRolePermissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModuleRolePermissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModuleRolePermissionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetModuleRolePermissionQuery(baseOptions?: Apollo.QueryHookOptions<GetModuleRolePermissionQuery, GetModuleRolePermissionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetModuleRolePermissionQuery, GetModuleRolePermissionQueryVariables>(GetModuleRolePermissionDocument, options);
      }
export function useGetModuleRolePermissionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetModuleRolePermissionQuery, GetModuleRolePermissionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetModuleRolePermissionQuery, GetModuleRolePermissionQueryVariables>(GetModuleRolePermissionDocument, options);
        }
export type GetModuleRolePermissionQueryHookResult = ReturnType<typeof useGetModuleRolePermissionQuery>;
export type GetModuleRolePermissionLazyQueryHookResult = ReturnType<typeof useGetModuleRolePermissionLazyQuery>;
export type GetModuleRolePermissionQueryResult = Apollo.QueryResult<GetModuleRolePermissionQuery, GetModuleRolePermissionQueryVariables>;
export const GetModulesDocument = gql`
    query GetModules {
  modules {
    id
    order
    title
    friendlyUrl
    icon
    superAdmin
    available
    translationKey {
      id
      key
    }
    userCreated {
      id
      role {
        id
        title
      }
      username
    }
    createdAt
    userUpdated {
      id
      role {
        id
        title
      }
      username
    }
    updatedAt
  }
}
    `;

/**
 * __useGetModulesQuery__
 *
 * To run a query within a React component, call `useGetModulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModulesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetModulesQuery(baseOptions?: Apollo.QueryHookOptions<GetModulesQuery, GetModulesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetModulesQuery, GetModulesQueryVariables>(GetModulesDocument, options);
      }
export function useGetModulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetModulesQuery, GetModulesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetModulesQuery, GetModulesQueryVariables>(GetModulesDocument, options);
        }
export type GetModulesQueryHookResult = ReturnType<typeof useGetModulesQuery>;
export type GetModulesLazyQueryHookResult = ReturnType<typeof useGetModulesLazyQuery>;
export type GetModulesQueryResult = Apollo.QueryResult<GetModulesQuery, GetModulesQueryVariables>;
export const GetModuleDocument = gql`
    query GetModule($id: Int) {
  module(id: $id) {
    id
    order
    title
    friendlyUrl
    icon
    superAdmin
    available
    translationKey {
      id
      key
    }
    userCreated {
      id
      role {
        id
        title
      }
      username
    }
    createdAt
    userUpdated {
      id
      role {
        id
        title
      }
      username
    }
    updatedAt
  }
}
    `;

/**
 * __useGetModuleQuery__
 *
 * To run a query within a React component, call `useGetModuleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModuleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModuleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetModuleQuery(baseOptions?: Apollo.QueryHookOptions<GetModuleQuery, GetModuleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetModuleQuery, GetModuleQueryVariables>(GetModuleDocument, options);
      }
export function useGetModuleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetModuleQuery, GetModuleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetModuleQuery, GetModuleQueryVariables>(GetModuleDocument, options);
        }
export type GetModuleQueryHookResult = ReturnType<typeof useGetModuleQuery>;
export type GetModuleLazyQueryHookResult = ReturnType<typeof useGetModuleLazyQuery>;
export type GetModuleQueryResult = Apollo.QueryResult<GetModuleQuery, GetModuleQueryVariables>;
export const GetPermissionsDocument = gql`
    query GetPermissions {
  permissions {
    id
    title
    available
    translationKey {
      id
      key
    }
    userCreated {
      id
      role {
        id
        title
      }
      username
    }
    createdAt
    userUpdated {
      id
      role {
        id
        title
      }
      username
    }
    updatedAt
  }
}
    `;

/**
 * __useGetPermissionsQuery__
 *
 * To run a query within a React component, call `useGetPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPermissionsQuery(baseOptions?: Apollo.QueryHookOptions<GetPermissionsQuery, GetPermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPermissionsQuery, GetPermissionsQueryVariables>(GetPermissionsDocument, options);
      }
export function useGetPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPermissionsQuery, GetPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPermissionsQuery, GetPermissionsQueryVariables>(GetPermissionsDocument, options);
        }
export type GetPermissionsQueryHookResult = ReturnType<typeof useGetPermissionsQuery>;
export type GetPermissionsLazyQueryHookResult = ReturnType<typeof useGetPermissionsLazyQuery>;
export type GetPermissionsQueryResult = Apollo.QueryResult<GetPermissionsQuery, GetPermissionsQueryVariables>;
export const GetPermissionDocument = gql`
    query GetPermission($id: Int) {
  permission(id: $id) {
    id
    title
    available
    translationKey {
      id
      key
    }
    userCreated {
      id
      role {
        id
        title
      }
      username
    }
    createdAt
    userUpdated {
      id
      role {
        id
        title
      }
      username
    }
    updatedAt
  }
}
    `;

/**
 * __useGetPermissionQuery__
 *
 * To run a query within a React component, call `useGetPermissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPermissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPermissionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPermissionQuery(baseOptions?: Apollo.QueryHookOptions<GetPermissionQuery, GetPermissionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPermissionQuery, GetPermissionQueryVariables>(GetPermissionDocument, options);
      }
export function useGetPermissionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPermissionQuery, GetPermissionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPermissionQuery, GetPermissionQueryVariables>(GetPermissionDocument, options);
        }
export type GetPermissionQueryHookResult = ReturnType<typeof useGetPermissionQuery>;
export type GetPermissionLazyQueryHookResult = ReturnType<typeof useGetPermissionLazyQuery>;
export type GetPermissionQueryResult = Apollo.QueryResult<GetPermissionQuery, GetPermissionQueryVariables>;
export const GetRolesDocument = gql`
    query GetRoles {
  roles {
    id
    title
    available
    translationKey {
      id
      key
    }
    userCreated {
      id
      role {
        id
        title
      }
      username
    }
    createdAt
    userUpdated {
      id
      role {
        id
        title
      }
      username
    }
    updatedAt
  }
}
    `;

/**
 * __useGetRolesQuery__
 *
 * To run a query within a React component, call `useGetRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRolesQuery(baseOptions?: Apollo.QueryHookOptions<GetRolesQuery, GetRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
      }
export function useGetRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRolesQuery, GetRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
        }
export type GetRolesQueryHookResult = ReturnType<typeof useGetRolesQuery>;
export type GetRolesLazyQueryHookResult = ReturnType<typeof useGetRolesLazyQuery>;
export type GetRolesQueryResult = Apollo.QueryResult<GetRolesQuery, GetRolesQueryVariables>;
export const GetRoleDocument = gql`
    query GetRole($id: Int) {
  role(id: $id) {
    id
    title
    available
    translationKey {
      id
      key
    }
    userCreated {
      id
      role {
        id
        title
      }
      username
    }
    createdAt
    userUpdated {
      id
      role {
        id
        title
      }
      username
    }
    updatedAt
  }
}
    `;

/**
 * __useGetRoleQuery__
 *
 * To run a query within a React component, call `useGetRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRoleQuery(baseOptions?: Apollo.QueryHookOptions<GetRoleQuery, GetRoleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRoleQuery, GetRoleQueryVariables>(GetRoleDocument, options);
      }
export function useGetRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRoleQuery, GetRoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRoleQuery, GetRoleQueryVariables>(GetRoleDocument, options);
        }
export type GetRoleQueryHookResult = ReturnType<typeof useGetRoleQuery>;
export type GetRoleLazyQueryHookResult = ReturnType<typeof useGetRoleLazyQuery>;
export type GetRoleQueryResult = Apollo.QueryResult<GetRoleQuery, GetRoleQueryVariables>;
export const GetTranslationKeysDocument = gql`
    query GetTranslationKeys {
  translationKeys {
    id
    key
  }
}
    `;

/**
 * __useGetTranslationKeysQuery__
 *
 * To run a query within a React component, call `useGetTranslationKeysQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTranslationKeysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTranslationKeysQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTranslationKeysQuery(baseOptions?: Apollo.QueryHookOptions<GetTranslationKeysQuery, GetTranslationKeysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTranslationKeysQuery, GetTranslationKeysQueryVariables>(GetTranslationKeysDocument, options);
      }
export function useGetTranslationKeysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTranslationKeysQuery, GetTranslationKeysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTranslationKeysQuery, GetTranslationKeysQueryVariables>(GetTranslationKeysDocument, options);
        }
export type GetTranslationKeysQueryHookResult = ReturnType<typeof useGetTranslationKeysQuery>;
export type GetTranslationKeysLazyQueryHookResult = ReturnType<typeof useGetTranslationKeysLazyQuery>;
export type GetTranslationKeysQueryResult = Apollo.QueryResult<GetTranslationKeysQuery, GetTranslationKeysQueryVariables>;
export const GetTranslationKeyDocument = gql`
    query GetTranslationKey($id: Int) {
  translationKey(id: $id) {
    id
    key
  }
}
    `;

/**
 * __useGetTranslationKeyQuery__
 *
 * To run a query within a React component, call `useGetTranslationKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTranslationKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTranslationKeyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTranslationKeyQuery(baseOptions?: Apollo.QueryHookOptions<GetTranslationKeyQuery, GetTranslationKeyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTranslationKeyQuery, GetTranslationKeyQueryVariables>(GetTranslationKeyDocument, options);
      }
export function useGetTranslationKeyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTranslationKeyQuery, GetTranslationKeyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTranslationKeyQuery, GetTranslationKeyQueryVariables>(GetTranslationKeyDocument, options);
        }
export type GetTranslationKeyQueryHookResult = ReturnType<typeof useGetTranslationKeyQuery>;
export type GetTranslationKeyLazyQueryHookResult = ReturnType<typeof useGetTranslationKeyLazyQuery>;
export type GetTranslationKeyQueryResult = Apollo.QueryResult<GetTranslationKeyQuery, GetTranslationKeyQueryVariables>;
export const GetTranslationLanguagesDocument = gql`
    query GetTranslationLanguages {
  translationLanguages {
    id
    code
    name
  }
}
    `;

/**
 * __useGetTranslationLanguagesQuery__
 *
 * To run a query within a React component, call `useGetTranslationLanguagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTranslationLanguagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTranslationLanguagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTranslationLanguagesQuery(baseOptions?: Apollo.QueryHookOptions<GetTranslationLanguagesQuery, GetTranslationLanguagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTranslationLanguagesQuery, GetTranslationLanguagesQueryVariables>(GetTranslationLanguagesDocument, options);
      }
export function useGetTranslationLanguagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTranslationLanguagesQuery, GetTranslationLanguagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTranslationLanguagesQuery, GetTranslationLanguagesQueryVariables>(GetTranslationLanguagesDocument, options);
        }
export type GetTranslationLanguagesQueryHookResult = ReturnType<typeof useGetTranslationLanguagesQuery>;
export type GetTranslationLanguagesLazyQueryHookResult = ReturnType<typeof useGetTranslationLanguagesLazyQuery>;
export type GetTranslationLanguagesQueryResult = Apollo.QueryResult<GetTranslationLanguagesQuery, GetTranslationLanguagesQueryVariables>;
export const GetTranslationLanguageDocument = gql`
    query GetTranslationLanguage($id: Int) {
  translationLanguage(id: $id) {
    id
    code
    name
  }
}
    `;

/**
 * __useGetTranslationLanguageQuery__
 *
 * To run a query within a React component, call `useGetTranslationLanguageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTranslationLanguageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTranslationLanguageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTranslationLanguageQuery(baseOptions?: Apollo.QueryHookOptions<GetTranslationLanguageQuery, GetTranslationLanguageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTranslationLanguageQuery, GetTranslationLanguageQueryVariables>(GetTranslationLanguageDocument, options);
      }
export function useGetTranslationLanguageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTranslationLanguageQuery, GetTranslationLanguageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTranslationLanguageQuery, GetTranslationLanguageQueryVariables>(GetTranslationLanguageDocument, options);
        }
export type GetTranslationLanguageQueryHookResult = ReturnType<typeof useGetTranslationLanguageQuery>;
export type GetTranslationLanguageLazyQueryHookResult = ReturnType<typeof useGetTranslationLanguageLazyQuery>;
export type GetTranslationLanguageQueryResult = Apollo.QueryResult<GetTranslationLanguageQuery, GetTranslationLanguageQueryVariables>;
export const GetTranslationValuesDocument = gql`
    query GetTranslationValues {
  translationValues {
    id
    value
    translationKey {
      id
      key
    }
    translationLanguage {
      id
      code
      name
    }
  }
}
    `;

/**
 * __useGetTranslationValuesQuery__
 *
 * To run a query within a React component, call `useGetTranslationValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTranslationValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTranslationValuesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTranslationValuesQuery(baseOptions?: Apollo.QueryHookOptions<GetTranslationValuesQuery, GetTranslationValuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTranslationValuesQuery, GetTranslationValuesQueryVariables>(GetTranslationValuesDocument, options);
      }
export function useGetTranslationValuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTranslationValuesQuery, GetTranslationValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTranslationValuesQuery, GetTranslationValuesQueryVariables>(GetTranslationValuesDocument, options);
        }
export type GetTranslationValuesQueryHookResult = ReturnType<typeof useGetTranslationValuesQuery>;
export type GetTranslationValuesLazyQueryHookResult = ReturnType<typeof useGetTranslationValuesLazyQuery>;
export type GetTranslationValuesQueryResult = Apollo.QueryResult<GetTranslationValuesQuery, GetTranslationValuesQueryVariables>;
export const GetTranslationValueDocument = gql`
    query GetTranslationValue($id: Int) {
  translationValue(id: $id) {
    id
    value
    translationKey {
      id
      key
    }
    translationLanguage {
      id
      code
      name
    }
  }
}
    `;

/**
 * __useGetTranslationValueQuery__
 *
 * To run a query within a React component, call `useGetTranslationValueQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTranslationValueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTranslationValueQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTranslationValueQuery(baseOptions?: Apollo.QueryHookOptions<GetTranslationValueQuery, GetTranslationValueQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTranslationValueQuery, GetTranslationValueQueryVariables>(GetTranslationValueDocument, options);
      }
export function useGetTranslationValueLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTranslationValueQuery, GetTranslationValueQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTranslationValueQuery, GetTranslationValueQueryVariables>(GetTranslationValueDocument, options);
        }
export type GetTranslationValueQueryHookResult = ReturnType<typeof useGetTranslationValueQuery>;
export type GetTranslationValueLazyQueryHookResult = ReturnType<typeof useGetTranslationValueLazyQuery>;
export type GetTranslationValueQueryResult = Apollo.QueryResult<GetTranslationValueQuery, GetTranslationValueQueryVariables>;
export const GetUsersDocument = gql`
    query GetUsers($skip: Int, $take: Int) {
  users(skip: $skip, take: $take) {
    id
    role {
      id
      title
    }
    username
    available
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const GetUserDocument = gql`
    query GetUser($id: Int) {
  user(id: $id) {
    id
    role {
      id
      title
    }
    username
    available
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const LoginDocument = gql`
    mutation Login($data: UserLoginInput!) {
  login(data: $data) {
    success
    message
    token
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;