import { styled } from '@mui/material'
import * as React from 'react'
import { ReactNode } from 'react'
import { Header } from '../header/Header'
import { Menu } from '../menu/Menu'
import styles from './Layout.module.scss'

interface LayoutProps {
  children: ReactNode
}

const drawerWidth = 240

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}))

export function Layout({ children }: LayoutProps) {
  const [open, setOpen] = React.useState(true)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  return (
    <div className={styles.layout_box}>
      <Header open={open} openMenu={handleDrawerOpen} />

      <Menu open={open} close={handleDrawerClose} drawerWidth={drawerWidth} />
      <Main open={open} sx={{ marginTop: '90px' }}>
        {children}
      </Main>
    </div>
  )
}
