import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import {
  RoleCreateInput,
  useGetTranslationKeysQuery,
  useCreateRoleMutation,
} from '@graphql/schema'
import {
  ButtonDefault,
  Content,
  Layout,
  TextInput,
  TitlePage,
  SelectInput,
} from '@components/index'
import { FormControlLabel, FormGroup, Switch } from '@mui/material'
import styles from '../../admin.module.scss'

export default function RoleCreate() {
  const navigate = useNavigate()

  const { data: dataTranslationKeys } = useGetTranslationKeysQuery()

  const [createRole, { error }] = useCreateRoleMutation()

  const [formData, setFormData] = useState<RoleCreateInput>({
    id_translation_key: 0,
    title: '',
    available: false,
  })

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }))
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      const response = await createRole({
        variables: {
          data: formData,
        },
      })

      if (response.data) {
        navigate('/admin/roles')
      }
    } catch (error) {
      console.error('Ocorreu um erro ao criar o tipo de usuário:', error)
    }
  }

  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
        footer: `<p>${error.message}</p>`,
      })
    }
  }, [error])

  const translationKeyItems =
    dataTranslationKeys?.translationKeys.map((role) => ({
      value: role.id,
      label: role.key,
    })) || []

  return (
    <Layout>
      <Content open={false}>
        <div className="text-center mt-4">
          <TitlePage title="Criar Novo Usuário Perfil" />
        </div>
        <div className={styles.form}>
          <form onSubmit={handleSubmit}>
            <div className={styles.form_grup_wrapp}>
              <div className={styles.form_grup}>
                <TextInput
                  type="text"
                  id="title"
                  label="Título"
                  name="title"
                  htmlFor="name"
                  value={formData.title}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className={styles.form_grup}>
                <SelectInput
                  label="Chave de tradução"
                  name="id_translation_key"
                  options={translationKeyItems}
                  value={formData.id_translation_key || ''}
                  onSelectInputChange={() => handleChange}
                  required
                />
              </div>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      name="available"
                      checked={!!formData.available}
                      onChange={(e) =>
                        setFormData((prevFormData) => ({
                          ...prevFormData,
                          available: e.target.checked,
                        }))
                      }
                    />
                  }
                  label="Ativo:"
                  labelPlacement="start"
                />
              </FormGroup>
            </div>
            <div className={styles.form_buttons}>
              <ButtonDefault
                variant="green"
                type="submit"
                sx={{ width: '150px' }}
              >
                Salvar
              </ButtonDefault>
              <ButtonDefault
                variant="red"
                type="submit"
                onClick={() => navigate('/admin/roles')}
                sx={{ width: '150px' }}
              >
                Cancelar
              </ButtonDefault>
            </div>
          </form>
        </div>
      </Content>
    </Layout>
  )
}
