import { Link } from 'react-router-dom'
import styles from './Filters.module.scss'
import { TextField } from '@mui/material'
import { ChangeEvent } from 'react'

export type FiltersProps = {
  url_create?: string
  label_create?: string
  id_input: string
  label_input: string
  value_input: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
}

export function Filters(props: FiltersProps) {
  const {
    url_create,
    label_create,
    id_input,
    label_input,
    value_input,
    onChange,
  } = props
  return (
    <div className={styles.filters}>
      <div className={styles.filters_wrapp}>
        <TextField
          id={id_input}
          label={label_input}
          variant="outlined"
          onChange={onChange}
          value={value_input}
          type="text"
          className={styles.filters_input}
        />
        {url_create && label_create && (
          <div className={styles.filters_btn}>
            <Link to={url_create}> {label_create}</Link>
          </div>
        )}
      </div>
    </div>
  )
}
