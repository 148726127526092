/* eslint-disable @typescript-eslint/no-explicit-any */
import NorthIcon from '@mui/icons-material/Explore'
import HomeIcon from '@mui/icons-material/Home'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import RotateRightIcon from '@mui/icons-material/RotateRight'
import SettingsIcon from '@mui/icons-material/Settings'
import StorageRoundedIcon from '@mui/icons-material/StorageRounded'
import { IconButton, Tooltip } from '@mui/material'
import { Coordinate } from 'ol/coordinate'
import React, { useState } from 'react'
import { useLayers } from '../../providers/layer-provider'
import { useMap, useReset } from '../../providers/map-provider'
import styles from './Map.module.scss'

export type View = {
  center: Coordinate
  zoom: number
}

interface MapActionsProps {
  vectorLayerRef: any
  openDrawer?: (action: 'config' | 'dba') => void
}

export const MapActions: React.FC<MapActionsProps> = ({
  vectorLayerRef,
  openDrawer,
}) => {
  const resetMap = useReset()
  const { map } = useMap()
  const { groupLayers, setGroupLayers } = useLayers()
  const [rotation, setRotation] = useState(0)

  const rotateMap = () => {
    const newRotation = (rotation + 90) % 360
    setRotation(newRotation)
    const view = map.olMap?.getView()
    view?.setRotation((newRotation * Math.PI) / 180)
  }

  const clearDrawings = () => {
    const vectorLayer = vectorLayerRef.current
    if (vectorLayer) {
      const source = vectorLayer.source
      if (source !== null) {
        source.clear()
      }
    }
  }

  const reset = () => {
    resetMap()

    // reset enabled layers
    const newGroupLayers = groupLayers.map((group) => ({
      ...group,
      layers: group.layers.map((layer) => ({
        ...layer,
        enabled: false,
      })),
    }))

    setGroupLayers(newGroupLayers)

    // clear drawings
    clearDrawings()

    const view = map.olMap?.getView()
    view?.setRotation(0)
  }

  return (
    <div className={styles.mapActions_header}>
      <div className={styles.mapActions_header_openDrawer}>
        <Tooltip title="Reiniciar Mapa">
          <IconButton
            onClick={reset}
            sx={{
              fontSize: '14px',
              color: 'black',
              display: 'flex',
              alignItems: 'center',
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
          >
            <>
              <HomeIcon />
            </>
          </IconButton>
        </Tooltip>
        <Tooltip title="Rotacionar Mapa">
          <IconButton
            onClick={rotateMap}
            sx={{
              fontSize: '14px',
              color: 'black',
              display: 'flex',
              alignItems: 'center',
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
          >
            {rotation === 0 && <NorthIcon />}
            {rotation === 90 && <RotateRightIcon />}
            {rotation === 180 && <RotateLeftIcon />}
            {rotation === 270 && <RotateRightIcon />}
          </IconButton>
        </Tooltip>
      </div>
      <div className={styles.mapActions_header_openDrawer}>
        <IconButton
          onClick={() => openDrawer && openDrawer('dba')}
          sx={{
            fontSize: '14px',
            color: 'black',
            display: 'flex',
            alignItems: 'center',
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }}
        >
          <>
            <StorageRoundedIcon />
            <span className="ml-1">Biblioteca de Dados</span>
          </>
        </IconButton>
        <IconButton
          onClick={() => openDrawer && openDrawer('config')}
          sx={{
            fontSize: '14px',
            color: 'black',
            display: 'flex',
            alignItems: 'center',
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }}
        >
          <>
            <SettingsIcon />
            <span className="ml-1">Ferramentas</span>
          </>
        </IconButton>
      </div>
    </div>
  )
}

export default MapActions
