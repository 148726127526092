import { Content } from '@interfaces/ui/Ui'
import mockUi from '@mocks/mockUI'
import { useState } from 'react'

const useUI = () => {
  const [data] = useState<Content>(mockUi)

  return data
}

export default useUI
