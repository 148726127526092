import { GetModulesQuery, useGetModulesLazyQuery } from '@graphql/schema'
import {
  Drawer,
  IconButton,
  List,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useEffect, useState } from 'react'
import Swal from 'sweetalert2'

import * as Icons from '@mui/icons-material'

import useUserData from '@lib/hooks/useUserData'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import OtherHousesOutlinedIcon from '@mui/icons-material/OtherHousesOutlined'
import { NavLink, useLocation } from 'react-router-dom'
import useUI from 'src/hooks/useUI'
import styles from './Menu.module.scss'

export type MenuProps = {
  open: boolean
  close: () => void
  drawerWidth: number
}

type IconName = keyof typeof Icons

function DynamicIcon({ iconName }: { iconName: string }) {
  // eslint-disable-next-line import/namespace
  const IconComponent = Icons[iconName as IconName]
  if (!IconComponent) {
    return null
  }
  return <IconComponent />
}

export function Menu(props: MenuProps) {
  const { open, close, drawerWidth } = props
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const location = useLocation()

  const [modules, setModules] = useState<GetModulesQuery['modules']>([])

  const [fetchModules, { loading, error, data }] = useGetModulesLazyQuery({
    fetchPolicy: 'network-only',
  })

  const isActive = (path: string) => {
    return location.pathname.startsWith(path)
  }

  const userData = useUserData()

  const routerAdmin = '/admin'

  useEffect(() => {
    if (!loading && data) {
      setModules(data?.modules || [])
    } else if (!loading && !data?.modules) {
      fetchModules()
    }
  }, [loading, data, fetchModules])

  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
        footer: `<p>${error.message}</p>`,
      })
    }
  }, [error])

  //hook para os dados da tela
  const content = useUI()

  return (
    <>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            backgroundColor: content?.pages?.admin?.color_primary,
            color: 'white',
            border: 0,
          },
        }}
        variant={isMobile ? 'temporary' : 'persistent'}
        anchor="left"
        open={open}
        className={styles.menu}
      >
        <div className={styles.menu_close}>
          <IconButton onClick={close}>
            <ChevronLeftIcon />
          </IconButton>
        </div>

        <List>
          <NavLink to={routerAdmin}>
            <button
              className={`${styles.menu_link} ${
                window.location.pathname === routerAdmin &&
                `${styles.menu_link_active}`
              }`}
            >
              <OtherHousesOutlinedIcon />
              Home
            </button>
          </NavLink>
          {modules
            .filter((module) => userData?.superAdmin || !module.superAdmin)
            .map((module) => (
              <NavLink
                to={`/admin/${module.friendlyUrl}`}
                key={`menu-${module.id}`}
              >
                <button
                  className={`${styles.menu_link} ${
                    isActive(`/admin/${module.friendlyUrl}`) &&
                    styles.menu_link_active
                  }`}
                >
                  <DynamicIcon iconName={module.icon} />
                  {module.title}
                </button>
              </NavLink>
            ))}
        </List>
      </Drawer>
    </>
  )
}
