import { useLoginMutation } from '@graphql/schema'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { ButtonDefault, Footer, TextInput } from '@components/index'

import { useAuth } from '@lib/contexts/AuthContext'

import useUI from 'src/hooks/useUI'
import styles from './Login.module.scss'

export default function Login() {
  const navigate = useNavigate()

  //hook para os dados da tela
  const content = useUI()

  const { setToken, getUserTypeFromLocalStorage } = useAuth()

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState<string | null>(null)

  const [login, { loading, error: errorMutation }] = useLoginMutation()

  const handleButtonClick = async () => {
    try {
      const response = await login({
        variables: {
          data: {
            username: username,
            password: password,
          },
        },
      })

      if (response.data?.login?.success) {
        if (response.data?.login?.token) {
          setToken(response.data.login.token)
        }
        setError(null)
        navigate('/admin')
      } else {
        console.log('error: ', response.data?.login?.message || 'Login falhou')
        setError(response.data?.login?.message || 'Login falhou')
      }
    } catch (e) {
      console.log('error: ', e)
      console.log('errorMutation: ', errorMutation)
      setError('Ocorreu um erro durante o login')
    }
  }

  useEffect(() => {
    const localUserType = getUserTypeFromLocalStorage()
    if (localUserType === 'admin') {
      navigate('/admin')
    } else if (localUserType) {
      navigate('/private')
    }
  }, [navigate, getUserTypeFromLocalStorage])

  return (
    <>
      <main className={styles.login}>
        <section
          className={styles.login_banner}
          style={{
            background: `url(${content?.pages?.login?.image}) no-repeat center center`,
            backgroundSize: 'cover',
          }}
        >
          <div
            className={styles.login_banner_mask}
            style={{ background: content?.pages?.login?.banner_mask }}
          ></div>
          <div className={styles.login_banner_text}>
            <h1>{content?.pages?.login?.title}</h1>
            <p>{content?.pages?.login?.description}</p>
          </div>
        </section>

        <section>
          <div className={styles.login_identify}>
            <div className={styles.login_identify_container}>
              <div className={styles.login_identify_container_logo}>
                <img
                  src={content?.pages?.login?.logo}
                  alt={content?.pages?.login?.alt_logo}
                />
              </div>
              <div className={styles.login_identify_container_form}>
                <TextInput
                  name="username"
                  label="Usuário:"
                  placeholder=""
                  type="text"
                  value={username}
                  onChange={(e) => {
                    setUsername(e.target.value)
                  }}
                />
                <TextInput
                  name="senha"
                  label="Senha:"
                  placeholder=""
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {error && <p style={{ color: 'red' }}>{error}</p>}
                <ButtonDefault
                  sx={{
                    fontSize: '1rem',
                    backgroundColor: content?.pages?.login?.background_button,
                    ':hover': {
                      backgroundColor:
                        content?.pages?.login?.background_button_hover,
                    },
                  }}
                  onClick={handleButtonClick}
                  disabled={loading}
                  loading={loading}
                  loadingPosition="start"
                >
                  ENVIAR
                </ButtonDefault>
              </div>
              <div className={styles.login_identify_container_info}>
                <div>
                  <h3>
                    <strong>Acesso Público:</strong>
                  </h3>
                  <ButtonDefault
                    href="/public"
                    variant="transparent"
                    sx={{
                      fontSize: '0.9rem',
                      color: content?.pages?.login?.link_color,
                      padding: '0px',
                      textDecoration: 'underline',
                      '&:hover': {
                        textDecoration: 'underline',
                      },
                    }}
                  >
                    Entrar no acesso público
                  </ButtonDefault>
                  <ButtonDefault
                    href="https://www.valereal.rs.gov.br/"
                    onClick={(event) => {
                      event.preventDefault()
                      event.stopPropagation()
                      window.open('https://www.valereal.rs.gov.br/', '_blank')
                    }}
                    variant="transparent"
                    sx={{
                      fontSize: '0.9rem',
                      color: content?.pages?.login?.link_color,
                      padding: '0px',
                      textDecoration: 'underline',
                      '&:hover': {
                        textDecoration: 'underline',
                      },
                    }}
                  >
                    Acessar site da Prefeitura
                  </ButtonDefault>
                </div>
              </div>
            </div>
            <Footer isPageLogin />
          </div>
        </section>
      </main>
    </>
  )
}
