import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
} from '@apollo/client'

const uri = process.env.REACT_APP_API_URL

const httpLink = new HttpLink({ uri: uri })

const authLink = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem('jwt')
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : '',
    },
  })
  return forward(operation)
})

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
})

export default client
