import { Content, Layout, TitlePage } from '@components/index'

export default function Dashboard() {
  return (
    <Layout>
      <Content open={false}>
        <TitlePage title="Dashboard" />
      </Content>
    </Layout>
  )
}
