import React, { createContext, useContext, useState, useEffect } from 'react'
import jwtDecode from 'jwt-decode'

interface DecodedToken {
  id: number
  id_role: number
  username: string
  iat: number
  exp: number
}

interface AuthContextProps {
  isAuthenticated: boolean
  userType: 'admin' | 'private' | null
  setToken: (token: string) => void
  removeToken: () => void
  getUserTypeFromLocalStorage: () => 'admin' | 'private' | null
}

type AuthProviderProps = {
  children: React.ReactNode
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined)

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [userType, setUserType] = useState<'admin' | 'private' | null>(null)

  const getUserTypeFromLocalStorage = (): 'admin' | 'private' | null => {
    const token = localStorage.getItem('jwt')
    if (token) {
      const decodedToken: DecodedToken = jwtDecode(token)
      return decodedToken.id_role === 1 ? 'admin' : 'private'
    }
    return null
  }

  useEffect(() => {
    const localUserType = getUserTypeFromLocalStorage()
    if (localUserType) {
      setUserType(localUserType)
    }
  }, [])

  const setToken = (token: string) => {
    localStorage.setItem('jwt', token)
    const decodedToken: DecodedToken = jwtDecode(token)
    setUserType(decodedToken.id_role === 1 ? 'admin' : 'private')
  }

  const removeToken = () => {
    localStorage.removeItem('jwt')
    setUserType(null)
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: userType !== null,
        userType,
        setToken,
        removeToken,
        getUserTypeFromLocalStorage,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}
