import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import React from 'react'

import styles from './DynamicTable.module.scss'

interface Feature {
  properties: { [key: string]: any }
}

interface DynamicTableProps {
  features: Feature[]
}

const DynamicTable: React.FC<DynamicTableProps> = ({ features }) => {
  const allKeys = Array.from(
    new Set(features.flatMap((feature) => Object.keys(feature.properties)))
  )

  return (
    <TableContainer component={Paper} className={styles.Dynamic_table}>
      <Table>
        <TableHead>
          <TableRow>
            {allKeys.map((key) => (
              <TableCell key={key}>{key}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {features.map((feature, idx) => (
            <TableRow key={idx}>
              {allKeys.map((key) => (
                <TableCell key={key}>
                  {feature.properties[key] || 'N/A'}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default DynamicTable
