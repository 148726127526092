import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import { useGetConfigurationQuery } from '@graphql/schema'
import {
  ButtonDefault,
  Content,
  Layout,
  TextInput,
  TitlePage,
} from '@components/index'
import { FormControlLabel, FormGroup, Switch } from '@mui/material'
import styles from '../../admin.module.scss'

export default function ConfigurationView() {
  const navigate = useNavigate()

  const { id: idParam } = useParams<{ id: string | undefined }>()
  const id = idParam ? parseInt(idParam, 10) : undefined

  const { data, loading, error } = useGetConfigurationQuery({
    variables: {
      id,
    },
  })

  useEffect(() => {
    if (!loading && !data?.configuration) {
      navigate('/admin/configurations')
    }
  }, [loading, data, navigate])

  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
        footer: `<p>${error.message}</p>`,
      })
    }
  }, [error])

  return (
    <Layout>
      <Content open={false}>
        <div className="text-center mt-4">
          <TitlePage title="Visualizar Tipo de Perfil" />
        </div>
        <div className={styles.form}>
          <form>
            <div className={styles.form_grup_wrapp}>
              <div className={styles.form_grup}>
                <TextInput
                  type="text"
                  id="titulo"
                  label="Título"
                  name="titulo"
                  htmlFor="titulo"
                  value={data?.configuration.title}
                  disabled
                />
              </div>
            </div>
            <div className={styles.form_grup_wrapp}>
              <div className={styles.form_grup}>
                <TextInput
                  type="text"
                  id="titulo"
                  label="Título"
                  name="titulo"
                  htmlFor="titulo"
                  value={data?.configuration.title}
                  disabled
                />
              </div>
            </div>
            <div className={styles.form_grup_wrapp}>
              <div className={styles.form_grup}>
                <TextInput
                  type="text"
                  id="titulo"
                  label="Título"
                  name="titulo"
                  htmlFor="titulo"
                  value={data?.configuration.title}
                  disabled
                />
              </div>
            </div>
            <div className={styles.form_grup_wrapp}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      name="available"
                      checked={data?.configuration.available}
                      disabled
                    />
                  }
                  label="Ativo:"
                  labelPlacement="start"
                />
              </FormGroup>
            </div>

            <div className={styles.form_buttons}>
              <ButtonDefault
                variant="blue"
                type="button"
                onClick={() => navigate('/admin/configurations')}
                sx={{ width: '150px' }}
              >
                Voltar
              </ButtonDefault>
            </div>
          </form>
        </div>
      </Content>
    </Layout>
  )
}
