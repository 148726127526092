import { Print } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { jsPDF } from 'jspdf'
import React from 'react'
import { useMap } from '../../../providers/map-provider'

const PrintButton: React.FC = () => {
  const { map } = useMap()

  const handleClick = async () => {
    if (!map.olMap) {
      console.error('Mapa não está disponível')
      return
    }

    const canvas = map.olMap.getViewport().querySelector('canvas')
    if (!canvas) {
      console.error('Não foi possível encontrar o canvas do mapa')
      return
    }

    const imgData = canvas.toDataURL('image/png')
    const pdf = new jsPDF({
      orientation: canvas.width > canvas.height ? 'landscape' : 'portrait',
      unit: 'px',
      format: [canvas.width, canvas.height],
    })

    pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height)
    pdf.save('mapa.pdf')
  }

  return (
    <div className="print">
      <IconButton
        size="small"
        onClick={handleClick}
        color="inherit"
        style={{ backgroundColor: '#fff' }}
      >
        <Print />
        <span>Imprimir</span>
      </IconButton>
    </div>
  )
}

export default PrintButton
