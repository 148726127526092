import React, { InputHTMLAttributes } from 'react'
import styles from './TextInput.module.scss'

export type TextInputProps = {
  label: string
  placeholder?: string
  name: string
  htmlFor?: string
  disabled?: boolean
} & InputHTMLAttributes<HTMLInputElement>

export function TextInput(props: TextInputProps) {
  const {
    type = 'text',
    label,
    name,
    placeholder,
    htmlFor,
    disabled,
    ...restProps
  } = props

  return (
    <div className={styles.textInput}>
      <div className={styles.textInput_label}>
        <label htmlFor={htmlFor}>{label}</label>
      </div>
      <div
        className={
          disabled ? styles.textInput_input_disabled : styles.textInput_input
        }
      >
        <input
          type={type}
          name={name}
          placeholder={placeholder}
          {...restProps}
        />
      </div>
    </div>
  )
}
