import React from 'react'
import styles from './TitlePage.module.scss'

interface TitlePageProps {
  title: string
}

export function TitlePage(props: TitlePageProps) {
  const { title } = props
  return <h1 className={styles.title}>{title}</h1>
}
