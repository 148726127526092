import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import {
  RoleUpdateInput,
  useGetRoleQuery,
  useGetTranslationKeysQuery,
  useUpdateRoleMutation,
} from '@graphql/schema'
import {
  ButtonDefault,
  Content,
  Layout,
  TextInput,
  TitlePage,
  SelectInput,
} from '@components/index'
import { FormControlLabel, FormGroup, Switch } from '@mui/material'
import styles from '../../admin.module.scss'

export default function RoleUpdate() {
  const navigate = useNavigate()

  const { data: dataTranslationKeys } = useGetTranslationKeysQuery()

  const { id: idParam } = useParams<{ id: string | undefined }>()
  const id = idParam ? parseInt(idParam, 10) : undefined

  const {
    data: dataRole,
    loading: loadingRole,
    error: errorRole,
  } = useGetRoleQuery({
    variables: {
      id,
    },
  })

  const [updateRole, { error: updateRoleError }] = useUpdateRoleMutation()

  const [formData, setFormData] = useState<RoleUpdateInput>({
    id: id,
    id_translation_key: 0,
    title: '',
    available: false,
  })

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }))
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      const response = await updateRole({
        variables: {
          data: formData,
        },
      })

      if (response.data) {
        navigate('/admin/roles')
      }
    } catch (error) {
      console.error('Ocorreu um erro ao criar o usuário:', error)
    }
  }

  useEffect(() => {
    if (!loadingRole && dataRole?.role) {
      setFormData({
        id: dataRole.role.id,
        id_translation_key: dataRole.role.translationKey.id,
        title: dataRole.role.title,
        available: dataRole.role.available,
      })
    } else if (!loadingRole && !dataRole?.role) {
      navigate('/admin/roles')
    }
  }, [loadingRole, dataRole, navigate])

  useEffect(() => {
    if (errorRole) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
        footer: `<p>${errorRole.message}</p>`,
      })
    }
  }, [errorRole])

  const translationKeyItems =
    dataTranslationKeys?.translationKeys.map((role) => ({
      value: role.id,
      label: role.key,
    })) || []

  useEffect(() => {
    if (updateRoleError) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
        footer: `<p>${updateRoleError.message}</p>`,
      })
    }
  }, [updateRoleError])

  return (
    <Layout>
      <Content open={false}>
        <div className="text-center mt-4">
          <TitlePage title="Editar Tipo de Perfil" />
        </div>
        <div className={styles.form}>
          <form onSubmit={handleSubmit}>
            <div className={styles.form_grup_wrapp}>
              <div className={styles.form_grup}>
                <TextInput
                  type="text"
                  id="title"
                  label="Título"
                  name="title"
                  htmlFor="name"
                  value={formData.title}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className={styles.form_grup}>
                <SelectInput
                  label="Chave de tradução"
                  name="id_translation_key"
                  options={translationKeyItems}
                  value={formData.id_translation_key || ''}
                  onSelectInputChange={() => handleChange}
                  required
                />
              </div>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      name="available"
                      checked={!!formData.available}
                      onChange={(e) =>
                        setFormData((prevFormData) => ({
                          ...prevFormData,
                          available: e.target.checked,
                        }))
                      }
                    />
                  }
                  label="Ativo:"
                  labelPlacement="start"
                />
              </FormGroup>
            </div>
            <div className={styles.form_buttons}>
              <ButtonDefault
                variant="green"
                type="submit"
                sx={{ width: '150px' }}
              >
                Salvar
              </ButtonDefault>
              <ButtonDefault
                variant="red"
                type="submit"
                onClick={() => navigate('/admin/roles')}
                sx={{ width: '150px' }}
              >
                Cancelar
              </ButtonDefault>
            </div>
          </form>
        </div>
      </Content>
    </Layout>
  )
}
