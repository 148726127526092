import { Map, UserAppbar } from '@components/index'

export default function Dashboard() {
  return (
    <>
      <Map />
      <div className="fixed z-50 right-0 top-4">
        <UserAppbar tooltip="você está logado com acesso privado" />
      </div>
    </>
  )
}
