import React from 'react'
import { Link } from 'react-router-dom'
import { Tooltip } from '@mui/material'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'

interface ActionsTableProps {
  url_edit?: string
  url_view?: string
  onClickDelete?: () => void
}

export function ActionsTable(props: ActionsTableProps) {
  const { url_edit, url_view, onClickDelete } = props
  return (
    <div className="flex">
      {url_edit && (
        <Tooltip title="Editar">
          <Link to={url_edit}>
            <EditOutlinedIcon className="text-orange-300" />
          </Link>
        </Tooltip>
      )}
      {url_view && (
        <Tooltip title="Visualizar">
          <Link to={url_view}>
            <RemoveRedEyeOutlinedIcon className="text-green-500 mx-3" />
          </Link>
        </Tooltip>
      )}
      {onClickDelete && (
        <Tooltip title="Excluir">
          <button onClick={onClickDelete}>
            <DeleteOutlineOutlinedIcon className="text-red-700" />
          </button>
        </Tooltip>
      )}
    </div>
  )
}
