import React, { ReactNode, createContext, useContext, useState } from 'react'
import { IGroupedLayerArrayItem, ILayersContextType } from './interface'

const LayersContext = createContext<ILayersContextType | undefined>(undefined)

export const useLayers = () => {
  const context = useContext(LayersContext)
  if (!context) {
    throw new Error('useLayers deve ser usado dentro de um LayersProvider')
  }
  return context
}

export const LayersProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [groupLayers, setGroupLayers] = useState<IGroupedLayerArrayItem[]>([])

  return (
    <LayersContext.Provider value={{ groupLayers, setGroupLayers }}>
      {children}
    </LayersContext.Provider>
  )
}
