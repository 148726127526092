import { fromLonLat } from 'ol/proj'
import { register } from 'ol/proj/proj4'
import proj4 from 'proj4'
import React, { ReactNode, createContext, useState } from 'react'

import { ILayer } from '../layer-provider'
import { IMapContextType, IMapSettings } from './interface'

export const MapContext = createContext<IMapContextType | undefined>(undefined)

const projectionName = process.env.REACT_APP_MAP_PROJECTION_NAME || 'EPSG:31982'
const projectionConfig = process.env.REACT_APP_MAP_PROJECTION_CONFIG || ''

if (projectionName && projectionConfig) {
  // eslint-disable-next-line import/no-named-as-default-member
  proj4.defs(projectionName, projectionConfig)
  register(proj4)
}

const brasilBoundsLatLon = [-73.982817, -33.751748, -34.793057, 5.271841]

const brasilBounds31982 = [
  fromLonLat([brasilBoundsLatLon[0], brasilBoundsLatLon[1]], 'EPSG:31982'),
  fromLonLat([brasilBoundsLatLon[2], brasilBoundsLatLon[3]], 'EPSG:31982'),
].flat()

const ortoimagemLayer = {
  id: '9dbac606-8b7b-4bd8-af3d-555e0a3416e1',
  name: 'Otimizada',
  tileWMSOptions: {
    url: 'https://geoserver.atagon.com.br:443/geoserver/ows?service=WMS&version=1.1.0&request=GetMap&layers=AW:ortoimagem_vale_real_otimizada&styles=&bbox=-180,-90,180,90&srs=EPSG:4326&format=image/png&TILED=true',
    params: {
      SERVICE: 'WMS',
      VERSION: '1.1.0',
      REQUEST: 'GetMap',
      STYLES: '',
      BBOX: '-180,-90,180,90',
      SRS: 'EPSG:4326',
      FORMAT: 'image/png',
      LAYERS: 'AW:ortoimagem_vale_real_otimizada',
      TILED: true,
    },
  },
}

export const initialMapSettings: IMapSettings = {
  projectionName,
  projectionConfig,
  center: fromLonLat(
    [
      parseFloat(process.env.REACT_APP_MAP_CENTER_LON || '-51.2551'),
      parseFloat(process.env.REACT_APP_MAP_CENTER_LAT || '-29.3948'),
    ],
    projectionName
  ),
  initialZoom: parseInt(process.env.REACT_APP_MAP_ZOOM || '15'),
  view: {
    center: fromLonLat(
      [
        parseFloat(process.env.REACT_APP_MAP_CENTER_LON || '-51.2551'),
        parseFloat(process.env.REACT_APP_MAP_CENTER_LAT || '-29.3948'),
      ],
      projectionName
    ),
    zoom: parseInt(process.env.REACT_APP_MAP_ZOOM || '15'),
  },
  minZoom: 4,
  maxZoom: 22,
  extent: brasilBounds31982,
  isLayerDetails: false,
  isMeasuring: false,
  isMeasuringArea: false,
  showFeatureInfo: false,
  baseMapLayer: 'osm',
  ortoimageEnabled: false,
  ortoimagemLayer: ortoimagemLayer as ILayer,
  lat: parseFloat(process.env.REACT_APP_MAP_CENTER_LAT || '-29.3948'),
  lon: parseFloat(process.env.REACT_APP_MAP_CENTER_LON || '-51.2551'),
  enableDrawMode: false,
  drawMode: null,
  cursorStyle: '',
  olMap: null,
}

export const MapProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [map, setMap] = useState<IMapSettings>({
    ...initialMapSettings,
  })

  return (
    <MapContext.Provider value={{ map, setMap }}>
      {children}
    </MapContext.Provider>
  )
}
